import { Button, TextField } from '@material-ui/core'
import { Container, ContainerContent, Content, DataStr, EachData, Page } from './admin.styles'
import React, { useRef, useState } from 'react'

import Alert from "@material-ui/lab/Alert"
import Icon from '@material-ui/core/Icon';
import Recaptcha from "react-recaptcha"
import {
    Snackbar,
} from "@material-ui/core"

function Admin() {
    const [senha, setSenha] = useState('')
    const [login, setLogin] = useState('')
    const [simu, setSimu] = useState([<p>Sem dados</p>])
    const [emp, setEmp] = useState([<p>Sem dados</p>])
    const [tit, setTit] = useState([<p>Sem dados</p>])
    const [msg, setMsg] = useState([<p>Sem dados</p>])
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [verifyed, setVerifyed] = useState(false)
    const [erro, setErro] = useState(false)

    const[iof, setIof] = useState('')

    function deleteRegister(id,table,pointers){
        let envData5 = { "Id": id, "Table": table, "Senha":senha, 'Login':login }
        fetch("https://api.credtech.me/admin", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "delete",
            body: JSON.stringify(envData5)
        }).then(res => res.json())
          .then(res => {res['Res']==='true' ? alert("Apagado com sucesso") : alert("Erro ao deletar")})
          .then(()=>{
              if (envData5.Table==='contato'){
                let envData4 = { "Senha": senha, "Table": "contato", "Login":login}
                fetch("https://api.credtech.me/admin", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "post",
                    body: JSON.stringify(envData4)
                })
                .then(res => res.json())
                .then(res => parseData(res,"contato"))
                .catch(err=>alert("erro"))
              }
              if (envData5.Table==='simulador'){
                let envData4 = { "Senha": senha, "Table": "simulador", "Login":login}
                fetch("https://api.credtech.me/admin", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "post",
                    body: JSON.stringify(envData4)
                })
                .then(res => res.json())
                .then(res => parseData(res,"simulador"))
                .catch(err=>alert("erro"))
              }
              if (envData5.Table==='emprestimo'){
                let envData4 = { "Senha": senha, "Table": "emprestimo", "Login":login}
                fetch("https://api.credtech.me/admin", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "post",
                    body: JSON.stringify(envData4)
                })
                .then(res => res.json())
                .then(res => parseData(res,"emprestimo"))
                .catch(err=>alert("erro"))
              }
              if (envData5.Table==='titulos'){
                let envData4 = { "Senha": senha, "Table": "titulos", "Login":login}
                fetch("https://api.credtech.me/admin", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "post",
                    body: JSON.stringify(envData4)
                })
                .then(res => res.json())
                .then(res => parseData(res,"titulos"))
                .catch(err=>alert("erro"))
              }
          })
    }

    function parseData(json,tipo) {
        var allData = []
        if (tipo==="simulador"){
            Object.keys(json).map(key => {
                allData.push(
                    <EachData key={json[key].id}>
                        <h3>{json[key].nome}</h3>
                        <DataStr>CPF/CNPJ: {json[key].cpfCnpj}</DataStr>
                        <DataStr>Email: {json[key].email}</DataStr>
                        <DataStr>Telefone: {json[key].telefone}</DataStr>
                        <DataStr>Valor: {json[key].valor}R$</DataStr>
                        <DataStr>Segmento: {json[key].segmento}</DataStr>
                        <DataStr>Ciclo: {json[key].ciclo}</DataStr>
                        <DataStr>Vezes: {json[key].vezes}x</DataStr>
                        <DataStr>Garantia: {json[key].garantia}</DataStr>
                        <DataStr>Data: {json[key].dataSol}</DataStr>
                        <Button variant='contained' onClick={() => deleteRegister(json[key].id,"simulador")}>Deletar</Button>
                    </EachData>
                )
                return true
            })
            setSimu(allData)
        }
        if (tipo==="emprestimo"){
            Object.keys(json).map(key => {
                allData.push(
                    <EachData key={json[key].id}>
                        <h3>{json[key].nome}</h3>
                        <DataStr>Tipo: {json[key].tipo==='1' ? "Pessoa física" : "Pessoa jurídica"}</DataStr>
                        <DataStr>Valor: {json[key].valor}</DataStr>
                        {json[key].tipo==='2' ? <DataStr>CNPJ: {json[key].CNPJ}</DataStr> : ''}
                        <DataStr>Segmento: {json[key].segmento}</DataStr>
                        <DataStr>Ciclo: {json[key].ciclo}</DataStr>
                        <DataStr>Vezes: {json[key].vezes}</DataStr>
                        <DataStr>Garantia: {json[key].garantia}</DataStr>
                        <DataStr>Email: {json[key].email}</DataStr>
                        <DataStr>Telefone: {json[key].telefone}</DataStr>
                        <DataStr>Endereço: {json[key].endereco}</DataStr>
                        <DataStr>CEP: {json[key].cep}</DataStr>
                        <DataStr>Selfie com doc:</DataStr>
                        <img widht='auto' height='auto' alt='Selfie com documento' src={json[key].selfieDoc}/>
                        <DataStr>Foto RG:</DataStr>
                        <img widht='auto' height='auto' alt='Foto RG' src={json[key].fotoRg}/>
                        <DataStr>Foto comprovante de endereço:</DataStr>
                        <img widht='auto' height='auto' alt='Compovante de endereço' src={json[key].fotoCompEnd}/>
                        <DataStr>Foto comprovante de renda:</DataStr>
                        <img widht='auto' height='auto' alt='Compovante de renda' src={json[key].fotoCompRenda}/>
                        <DataStr>RG ou CPF: {json[key].rgOUcpf}R$</DataStr>
                        <DataStr>Foto comprovante de endreço socio:</DataStr>
                        <img widht='auto' alt='Compovante de endereço sócio' src={json[key].compEndSocio}/>
                        <DataStr>dev1: {json[key].dev1 === '' ? "Não há" : json[key].dev1}</DataStr>
                        <DataStr>dev2: {json[key].dev2 === '' ? "Não há" : json[key].dev2}</DataStr>
                        <DataStr>dev3: {json[key].dev3 === '' ? "Não há" : json[key].dev3}</DataStr>
                        <DataStr>Data: {json[key].dataSol}</DataStr>
                        <Button variant='contained' onClick={() => deleteRegister(json[key].id,"emprestimo",[
                            json[key].selfieDoc,
                            json[key].fotoCompEnd,
                            json[key].fotoRg,
                            json[key].fotoCompRenda,
                            json[key].compEndSocio
                        ])}>Deletar</Button>
                    </EachData>
                )
                return true
            })
            setEmp(allData)
        }
        if (tipo==="titulos"){
            Object.keys(json).map(key => {
                allData.push(
                    <EachData key={json[key].id}>
                        <h3>{json[key].nome}</h3>
                        <DataStr>CPF ou CNPJ: {json[key].cpfCnpj}</DataStr>
                        <DataStr>Endereco: {json[key].endereco}</DataStr>
                        <DataStr>CEP: {json[key].cep}</DataStr>
                        <DataStr>Email: {json[key].email}</DataStr>
                        <DataStr>Telefone: {json[key].telefone}</DataStr>
                        <img widht='auto' height='auto' alt='Foto frontal cheque' src={json[key].fotoFrente}/>
                        <img widht='auto' height='auto' alt='Compovante traseira cheque' src={json[key].fotoTras}/>
                        <DataStr>Valor: {json[key].valor}R$</DataStr>
                        <DataStr>Garantia: {json[key].garantia}</DataStr>
                        <DataStr>Pré data cheque: {json[key].preDatado}</DataStr>
                        <DataStr>Data da solicitação: {json[key].dataSol}</DataStr>
                        <Button variant='contained' onClick={() => deleteRegister(json[key].id,"titulos",[
                            json[key].fotoTras,
                            json[key].fotoFrente
                        ])}>Deletar</Button>
                    </EachData>
                )
                return true
            })
            setTit(allData)
        }
        if (tipo==="contato"){
            fetch("https://api.credtech.me/settings", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "GET",
            })
            .then(res => res.json())
            .then(res => setIof(res.IOF))
            .catch(err=>alert("erro contato"))
            Object.keys(json).map(key => {
                allData.push(
                    <EachData key={json[key].id}>
                        <h3>{json[key].nome}</h3>
                        <DataStr>Email: {json[key].email}</DataStr>
                        <DataStr>Telefone: {json[key].telefone}</DataStr>
                        <DataStr>Mensagem: {json[key].mensagem}</DataStr>
                        <Button variant='contained' onClick={() => deleteRegister(json[key].id,"contato")}>Deletar</Button>
                    </EachData>
                )
                return true
            })
            setMsg(allData)
        }
    }

    function getData(type){
        if (verifyed){
            let envData = { "Senha": senha, "Table": "simulador", "Login":login}
            fetch("https://api.credtech.me/admin", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "post",
                body: JSON.stringify(envData)
            })
            .then(res => res.json())
            .then(res => {
                if (res['Senha']==='err'){
                    alert('Confira os dados de login')
                } else{
                    parseData(res,"simulador")
                }
            })
            .catch(err=>alert("Erro simulador"))

            let envData2 = { "Senha": senha, "Table": "emprestimo", "Login":login}
            fetch("https://api.credtech.me/admin", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "post",
                body: JSON.stringify(envData2)
            })
            .then(res => res.json())            
            .then(res => {if (res['Senha']!=='err'){parseData(res,"emprestimo")}})
            .catch(err=>alert("Erro emprestimo"))

            let envData3 = { "Senha": senha, "Table": "titulos", "Login":login}
            fetch("https://api.credtech.me/admin", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "post",
                body: JSON.stringify(envData3)
            })
            .then(res => res.json())
            .then(res => {if (res['Senha']!=='err'){parseData(res,"titulos")}})
            .catch(err=>alert("erro titulos"))
            
            let envData4 = { "Senha": senha, "Table": "contato", "Login":login}
            fetch("https://api.credtech.me/admin", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "post",
                body: JSON.stringify(envData4)
            })
            .then(res => res.json())
            .then(res => {if (res['Senha']!=='err'){parseData(res,"contato")}})
            .catch(err=>alert("erro contato"))

        } else{
            setErro('block');
        }
    }

    function setNewIof(){
        let envData = { "Senha": senha, "iof": iof==='1'? 0 : 1, "Login":login}
        fetch("https://api.credtech.me/settings", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "put",
            body: JSON.stringify(envData)
        })
        .then(res => {
            fetch("https://api.credtech.me/settings", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "GET",
            })
            .then(res => res.json())
            .then(res => setIof(res.IOF))
            .catch(err=>alert("erro contato"))
        })
        .catch(err=>alert("erro contato"))
    }

    const captcha = useRef(null)

    return (
        <Page>
            <h3 id='1'>Pagina do administrador</h3>
            <TextField
                id="outlined-basic"
                label="LOGIN"
                variant="outlined"
                style={{ marginTop: "20px", marginBottom: "20px" }}
                onChange={event => setLogin(event.target.value)}
            />
            <TextField
                id="outlined-basic"
                type='password'
                label="SENHA"
                variant="outlined"
                style={{ marginTop: "20px", marginBottom: "20px" }}
                onChange={event => setSenha(event.target.value)}
            />
            <Recaptcha
              elementID="recap-simulador"
              sitekey="6LfE8PQUAAAAAM-Ulcpiik6EHzqpjSj6knNN4-Kl"
              render="explicit"
              onloadCallback={()=>{}}
              verifyCallback={() => {
                setVerifyed(true)
              }}
              hl="pt-BR"
              size="compact"
              ref={captcha}
            />
            <Snackbar
              open={erro === "block" ? true : false}
              autoHideDuration={6000}
              onClose={() => {
                setErro("none")
              }}
            >
              <Alert
                onClose={() => {
                  setErro("none")
                }}
                severity="error"
              >
                verifique o reCAPTCHA!
              </Alert>
            </Snackbar>
            <Button variant='contained' onClick={() => getData()}>Logar</Button>
            <div style={{display: iof==='' ? 'none' : 'block'}}>
                <h2>IOF status = {iof==="1" ? "Habilitado" : "Desabilitado"}</h2>
                <Button variant='contained' onClick={() => setNewIof()}>AlternarStatus</Button>
            </div>
            <Content>
                <Container height={open ? 'auto' : '0px'} paddingBottom={open ? (document.getElementById('content').clientHeight+10)+"px" : '10px'}>
                    <div style={{cursor:'pointer'}} onClick={()=>setOpen(!open)}>
                        <Icon>{'expand_more'}</Icon>
                    </div>
                    <p style={{cursor:'pointer'}} onClick={()=>setOpen(!open)}>Pedidos de simulação ({simu.length})</p>
                    <ContainerContent height={open ? 'auto' : '0px'} id='content'>
                        {simu.map(el=>{return el})}
                    </ContainerContent>
                </Container>
            </Content>
            <Content>
                <Container height={open2 ? 'auto' : '0px'} paddingBottom={open2 ? (document.getElementById('content2').clientHeight+10)+"px" : '10px'}>
                    <div style={{cursor:'pointer'}} onClick={()=>setOpen2(!open2)}>
                        <Icon>{'expand_more'}</Icon>
                    </div>
                    <p style={{cursor:'pointer'}} onClick={()=>setOpen2(!open)}>Pedidos de empréstimo ({emp.length})</p>
                    <ContainerContent height={open2 ? 'auto' : '0px'} id='content2'>
                        {emp.map(el=>{return el})}
                    </ContainerContent>
                </Container>
            </Content>
            <Content>
                <Container height={open3 ? 'auto' : '0px'} paddingBottom={open3 ? (document.getElementById('content3').clientHeight+10)+"px" : '10px'}>
                    <div style={{cursor:'pointer'}} onClick={()=>setOpen3(!open3)}>
                        <Icon>{'expand_more'}</Icon>
                    </div>
                    <p style={{cursor:'pointer'}} onClick={()=>setOpen3(!open3)}>Pedidos de desconto de títulos ({tit.length})</p>
                    <ContainerContent height={open3 ? 'auto' : '0px'} id='content3'>
                        {tit.map(el=>{return el})}
                    </ContainerContent>
                </Container>
            </Content>
            <Content>
                <Container height={open4 ? 'auto' : '0px'} paddingBottom={open4 ? (document.getElementById('content4').clientHeight+10)+"px" : '10px'}>
                    <div style={{cursor:'pointer'}} onClick={()=>setOpen4(!open)}>
                        <Icon>{'expand_more'}</Icon>
                    </div>
                    <p style={{cursor:'pointer'}} onClick={()=>setOpen4(!open4)}>Pedidos de contato ({msg.length})</p>
                    <ContainerContent height={open4 ? 'auto' : '0px'} id='content4'>
                        {msg.map(el=>{return el})}
                    </ContainerContent>
                </Container>
            </Content>         
        </Page>
    );
}

export default Admin;