import {
  Button,
  Snackbar,
  TextField,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core"
import { Cont, ElementsGrid, Info } from "./contato.style"
import { Content, Header } from "../faq/faq.styles"
import React, { useEffect, useState } from "react"

import Alert from "@material-ui/lab/Alert"
import { BgContainer } from "../../components/header/header.styles"
import Email from "../../images/email.png"
import Footer from "../../components/footer/footer"
import Map from "../../components/MapBox/Map"
import Navbar from "../../components/navbar/navbar"
import Phone from "../../images/phone.png"
import Pin from "../../images/pin.png"
import Recaptcha from "react-recaptcha"
import bg from "../../images/bg.png"
import bg2 from "../../images/bg2.png"

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "10px",
  },
  btn: {
    width: "100%",
    backgroundColor: "#3380ce",
    color: "#fff",
    margin: "10px auto 10px auto",
    "&:hover": {
      backgroundColor: "#1a4e82",
    },
  },
  btnUP: {
    backgroundColor: "#f39d09",
    width: "100%",
    color: "#fff",
    margin: "10px auto 10px auto",
    "&:hover": {
      backgroundColor: "#f39000",
    },
  },
  btnBack: {
    marginBottom: "20px",
    backgroundColor: "rgb(250,250,250)",
    color: "#3380ce",
    width: "100%",
    boxShadow: "0 0 0 0",

    "&:hover": {
      backgroundColor: "#3380ce",
      boxShadow: "0 0 0 0",
      color: "white",
    },
  },
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3380ce",
    },
  },
})

export default function Contato() {
  const [nome, setNome] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [menssagem, setMenssagem] = useState("")
  const [verifyed, setVerifyed] = useState(false)
  const classes = useStyles()

  const [erro1, setErro1] = useState("none")
  const [erro2, setErro2] = useState("none")
  const [erro3, setErro3] = useState("none")
  const [erro5, setErro5] = useState('none')
  const [erro10, setErro10] = useState('none')

  function enviar() {
    if (verifyed) {
      let envData = {
        Nome: nome,
        Email: email,
        Telefone: telefone,
        Mensagem: menssagem,
      }

      fetch("https://api.credtech.me/contato", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(envData),
      })
        .then(res => res.json())
        .then(res => verifyResponse(res))
    }
  }

  function verifyResponse(envData) {
    if (envData["Nome"] === "err") {
      setErro1("block")
    } else {
      setErro1("none")
    }
    if (envData["Email"] === "err") {
      setErro2("block")
    } else {
      setErro2("none")
    }
    if (envData["Mensagem"] === "err") {
      setErro3("block")
    } else {
      setErro3("none")
    }
    if (envData["Telefone"] === "err") {
      setErro5("block")
    } else {
      setErro5("none")
    } 
    if (envData['Res'] === 'true'){
      alert('Mensagem enviada com sucesso!')
      setMenssagem("")
      setNome("")
      setTelefone("")
      setEmail("")
    }
  }

  const [margin, setMargin] = useState()

  useEffect(() => {
    setMargin(document.getElementById("1").clientHeight + "px")
  }, [])


  function teleMask(v){
    v = v.replace(/\D/g, '')
    let t = v.length

    switch(t){
      default:
        if(t >= 11){
          v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{5})(\d{4})/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1')
          setTelefone(v)
        }
        break
      case 8:
        v = v.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 9:
        v = v.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 10:
        v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{4})(\d{4})/, '$1-$2')
        setTelefone(v)
        break
        
    }
    setTelefone(v)
  }
  return (
    <ThemeProvider theme={theme}>
      <Header id="1" marginbottom={margin}>
        <div style={{ padding: "0 10% 0 10%" }}>
          <Navbar />
        </div>
        <Content>
          <h2
            className="light"
            onClick={() => alert(document.getElementById("1").clientHeight)}
          >
            Contato
          </h2>
          <h5 className="light" style={{ textAlign: "center" }}>
            Insira alguns dados e entre em contato conosco.
          </h5>
        </Content>
        <BgContainer img={bg2} speed="13s" height top="50%" />
        <BgContainer img={bg} speed="10s" height top="50%" />
      </Header>

      <ElementsGrid style={{ marginBottom: "50px" }}>
          <div>
            <TextField
              error={erro1 === "block" ? true : false}
              id="nome"
              variant="outlined"
              label="Nome"
              value={nome}
              size="small"
              required
              onChange={event => setNome(event.target.value)}
              style={{ width: "100%", margin: "10px 0 10px 0" }}
            ></TextField>
            <TextField
              error={erro2 === "block" ? true : false}
              id="email"
              variant="outlined"
              label="Email"
              value={email}
              size="small"
              required
              onChange={event => setEmail(event.target.value)}
              style={{ width: "100%", margin: "10px 0 10px 0" }}
            ></TextField>
            <TextField
              error={erro5 === "block" ? true : false}
              id="telefone"
              variant="outlined"
              label="Telefone"
              value={telefone}
              size="small"
              required
              onChange={event => teleMask(event.target.value)}
              style={{ width: "100%", margin: "10px 0 10px 0" }}
            ></TextField>
            <textarea
              onChange={event => setMenssagem(event.target.value)}
              value={menssagem}
              placeholder="Digite sua menssagem"
              className={erro3 === "none" ? "placeHolderDef" : "placeHolderRed"}
              style={{
                width: "100%",
                margin: "10px 0 10px 0",
                height: "100px",
                borderRadius: "4px",
                padding: "5px",
                boxSizing: "border-box",
                resize: "none",
                outline: "none",
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                borderColor: erro3 === "none" ? "rgb(190,190,190)" : "red",
              }}
            ></textarea>

              <Recaptcha
                elementID= 'recap-cnpj' 
                sitekey="6LfE8PQUAAAAAM-Ulcpiik6EHzqpjSj6knNN4-Kl"
                render="explicit"
                onloadCallback={()=>{}}
                verifyCallback={() => {setVerifyed(true)}}
                hl="pt-BR"
                size='compact'
              />
              <Snackbar open={erro10 === 'block'? true : false} autoHideDuration={6000} onClose={() => {setErro10('none')}}>
                <Alert onClose={() => {setErro10('none')}} severity="error">
                  verifique o reCAPTCHA!
                </Alert>
              </Snackbar> 

            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => enviar()}
            >
              Enviar
            </Button>
          </div>
        <Map />
        <Cont>
          <Info>
            <img alt='' src={Email}></img>
            <div>
              <h4>Email</h4>credtechnatal@gmail.com
            </div>
          </Info>
          <Info>
            <img alt='' src={Phone}></img>
            <div>
              <h4>Telefone</h4>(84) 99408-4212{<br></br>} (84) 99970-2626
            </div>
          </Info>
          <Info>
            <img alt='' src={Pin}></img>
            <div>
                <h4>Localização</h4>Rua Orlando De Lima, 465, {<br></br>} Bloco D –
              Ponta Negra, Natal – RN.
            </div>
          </Info>
        </Cont>
      </ElementsGrid>
      <Footer />
    </ThemeProvider>
  )
}