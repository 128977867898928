import {Container, Img, NameContainer, Text} from './cardrev.styles'

import React from 'react';

export default function Card(props){
    return(
        <Container>
            <Img src={props.img}/>
            <NameContainer>
                <h5>{props.name}</h5>
                <p style={{color: "#f39d09"}}>{props.func}</p></NameContainer>
            <Text><p>{props.content}</p></Text>
        </Container>
    );

}