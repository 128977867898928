import styled from "styled-components"

export const MainContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  padding-bottom: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;
  padding: 0 10% 0 10%;
  margin: 20px 0 0 0;

  &>:last-child{
    grid-column: 1/3;
  };
  
  &>:nth-child(2)>div{
    align-self: flex-end;
  }
  
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    justify-content: center;

    >:first-child{
      grid-row: 2;
    }

    >div:last-child{
      grid-column: 1/1;
    }

    &>:nth-child(2)>div{
    align-self: flex-start;
  }
  }
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;

  @media only screen and (max-width: 900px) {
    margin-top: 20px;

    > div > div > p {
      text-align: center;
    }
  }
`

export const BgContainer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(${props => props.img});
  background-position: 0;
  user-select: none;
  animation: animate ${props => props.speed} linear infinite alternate;
  background-position-y: top;

  @media only screen and (min-width: 900px) {
    transform: translateY(-80px);
  }

  @media only screen and (max-width: 900px) {
    transform: translateY(-50px);
  }

  @keyframes animate {
    0% {
      background-position-x: 0px;
    }
    100% {
      background-position-x: 1920px;
    }
  }
`

export const Social = styled.div`
  text-align: center;
  & > a {
    margin-right: 10px;
  }
`

export const BottomOver = styled.div`
  background-color: #3380ce;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
`
