import React, { useState } from "react"
import ReactMapGl, { Marker } from "react-map-gl"

import ApartmentIcon from "@material-ui/icons/Apartment"

export default function Map() {
  const [viewport, setViewport] = useState({
    latitude: -5.878851,
    longitude: -35.19627,
    width: "100%",
    height: "400px",
    zoom: 13,
  })

  return (
    <div style={{ width: "100%", heigth: "100%" }}>
      <ReactMapGl
        {...viewport}
        mapboxApiAccessToken="pk.eyJ1IjoidC13ZXNsbGV5IiwiYSI6ImNrOXhlcnc4bDBqaWIzbG5qa25neXFrOHgifQ.3vIn8uYlt4bf_RW4yhqlFg"
        onViewportChange={viewport => {
          setViewport(viewport)
        }}
      >
        <Marker longitude={-35.19627} latitude={-5.878851}>
          <div style={{ fontSize: "8px" }}>
            <ApartmentIcon></ApartmentIcon>CredTech
          </div>
        </Marker>
      </ReactMapGl>
    </div>
  )
}
