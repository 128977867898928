import "./soli.css"

import {
  AnimationLoading,
  AnimationMsg,
  AnimationOver,
  SubContainer,
} from "../../components/simuladorv2/simulador.styles"
import {
  ButtonContainerSoli,
  Classic,
  Container,
  ContainerFirst,
  ContainerMain,
  SideToSide,
} from "./soli_emp.styles"
import { Content, Header } from "../faq/faq.styles"
import {
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Snackbar,
  TextField,
  ThemeProvider,
  createMuiTheme,
  makeStyles
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import Alert from '@material-ui/lab/Alert';
import { BgContainer } from "../../components/header/header.styles"
import Button from "@material-ui/core/Button"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import Footer from "../../components/footer/footer"
import Navbar from "../../components/navbar/navbar"
import Recaptcha from 'react-recaptcha'
import { SocialIcon } from "react-social-icons"
import bg from "../../images/bg.png"
import bg2 from "../../images/bg2.png"
import loading from "../../images/loading.png"

//import do reCAPTCHA

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "10px",
  },
  btn: {
    width: "100%",
    backgroundColor: "#3380ce",
    color: "#fff",
    margin: "10px auto 10px auto",
    "&:hover": {
      backgroundColor: "#1a4e82",
    },
  },
  btnUP: {
    backgroundColor: "#f39d09",
    width: "100%",
    color: "#fff",
    margin: "10px auto 10px auto",
    "&:hover": {
      backgroundColor: "#f39000",
    },
  },
  btnBack: {
    marginBottom: "20px",
    backgroundColor: "rgb(250,250,250)",
    color: "#3380ce",
    width: "100%",
    boxShadow: "0 0 0 0",

    "&:hover": {
      backgroundColor: "#3380ce",
      boxShadow: "0 0 0 0",
      color: "white",
    },
  },
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3380ce",
    },
  },
})

//export default
function SolicitarEmprestimo({ props }) {
  const [page, setPage] = useState(1) //1 - pagina inicial 2 - cpf 3 - cnpj
  const classes = useStyles()
  //informações
  const [nome, setNome] = useState("")
  const [endereco, setEndereco] = useState("")
  const [enderecoN, setEnderecoN] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [cpf_cnpj, setCpf_cnpj] = useState("")
  const [rendimentoMensal, setRendimento] = useState("")
  const [scpf1, setScpf1] = useState("")
  const [scpf2, setScpf2] = useState("")
  const [scpf3, setScpf3] = useState("")
  const [CEP, setCEP] = useState("")
  //requisitos de valor
  const [valor, setValor] = useState(100)
  const [quantasVezes, setVezes] = useState(1)
  const [ciclo, setCiclo] = useState({
    semanal: true,
    quinzenal: false,
    mensal: false,
    sliderMax: 12,
    sliderMin: 1,
  })
  const [segmento, setSegmento] = useState("Comercio")
  const [titulo, setTitulo] = useState("SemGarantia")
  //arquivos
  const [comprovanteEndereco, setComprovanteEndereco] = useState("")
  const [comprovanteCpfCnpj, setComprovanteCpfCnpj] = useState("")
  const [comprovanteRgCnh, setComprovanteRgCnh] = useState("")
  const [comprovanteRenda, setComprovanteRenda] = useState("")
  const [comprovanteEndSocio, setComprovanteEndSocio] = useState("")
  const [cnpj, setCnpj] = useState('')
  //log de erros
  const [erro1, setErro1] = useState("none")
  const [erro2, setErro2] = useState("none")
  const [erro3, setErro3] = useState("none")
  const [erro4, setErro4] = useState("none")
  const [erro5, setErro5] = useState("none")
  const [erro6, setErro6] = useState("none")
  const [erro7, setErro7] = useState("none")
  const [erro8, setErro8] = useState("none")
  const [erro11, setErro11] = useState("none")
  const [erro12, setErro12] = useState("none")
  const [erro14, setErro14] = useState("none")
  const [erro15, setErro15] = useState("none")
  const [erro16, setErro16] = useState("none")
  const [erro17, setErro17] = useState("none")
  const [erro18, setErro18] = useState("none")
  const [erro19, setErro19] = useState("none")
  const [erro20, setErro20] = useState("none")
  const [erro21, setErro21] = useState("none")
  const [erro22, setErro22] = useState("none")
  //reCaptcha
  const [verifyed, setVerifyed] = useState(false)
  //variavel de testes
  const [animationOn, setAnimationOn] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [msgUser, setMsgUser] = useState("Sucesso")

  function sendRequest(tipo) {
    if(verifyed){
      setAnimationOn(true)

      let endereco2 = endereco + "," + enderecoN

      let cicle

      if (ciclo.mensal === true) {
        cicle = "mensal"
      }
      if (ciclo.quinzenal === true) {
        cicle = "quinzenal"
      }
      if (ciclo.semanal === true) {
        cicle = "semanal"
      }

      if (endereco2.length === 1) {
        endereco2 = ""
      }

      let envData = {
        Tipo: tipo,
        Nome: nome,
        CNPJ: cnpj,
        Valor: valor,
        Segmento: segmento,
        Ciclo: cicle,
        Vezes: quantasVezes,
        Garantia: titulo,
        Email: email,
        Telefone: telefone,
        Endereco: endereco2,
        CEP: CEP,
        SelfieDoc: comprovanteCpfCnpj,
        RendaMensal: rendimentoMensal,
        FotoRg: comprovanteRgCnh,
        FotoCompRenda: comprovanteRenda,
        FotoCompEnd: comprovanteEndereco,
        RGouCPF: cpf_cnpj,
        CompEndSocio: comprovanteEndSocio,
        dev1: scpf1,
        dev2: scpf2,
        dev3: scpf3,
      }

      fetch("https://api.credtech.me/solEmp", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(envData),
      })
        .then(res => res.json())
        .then(res => verifyResponse(res))
        .catch(() => setMsgUser("Erro de conexão"))
        .finally(() => {
          setIsShow(true)
          setTimeout(() => setAnimationOn(false), 1000)
          setTimeout(() => setIsShow(false), 1200)
        })
    }else{
      setErro21('block')
    }
  }




  function verifyResponse(envData) {
    if (envData["CompEndSocio"] === "err") {
      setErro19("block")
    } else {
      setErro19("none")
    }
    if (envData["Res"] === "true") {
      setMsgUser("Sucesso")
    }
    if (envData["Res"] === "false") {
      setMsgUser("Confira os campos e tente novamente")
    }
    if (envData["Nome"] === "err") {
      setErro18("block")
    } else {
      setErro18("none")
    }
    if (envData["Endereco"] === "err") {
      setErro1("block")
      setErro2("block")
    } else {
      setErro1("none")
      setErro2("none")
    }
    if (envData["CNPJ"] === 'err'){
      setErro22('block')
    } else{
      setErro22('none')
    }
    if (envData["FotoCompEnd"] === "err") {
      setErro3("block")
    } else {
      setErro3("none")
    }
    if (envData["RGouCPF"] === "err") {
      setErro4("block")
    } else {
      setErro4("none")
    }
    if (envData["SelfieDoc"] === "err") {
      setErro5("block")
    } else {
      setErro5("none")
    }
    if (envData["FotoRg"] === "err") {
      setErro6("block")
    } else {
      setErro6("none")
    }
    if (envData["RendaMensal"] === "err") {
      setErro7("block")
    } else {
      setErro7("none")
    }
    if (envData["FotoCompRenda"] === "err") {
      setErro8("block")
    } else {
      setErro8("none")
    }
    if (envData["Ciclo"] === "err") {
      setErro11("block")
    } else {
      setErro11("none")
    }
    if (envData["CEP"] === "err") {
      setErro12("block")
    } else {
      setErro12("none")
    }
    if (envData["dev1"] === "err") {
      setErro14("block")
    } else {
      setErro14("none")
    }
    if (envData["dev2"] === "err") {
      setErro15("block")
    } else {
      setErro15("none")
    }
    if (envData["dev3"] === "err") {
      setErro16("block")
    } else {
      setErro16("none")
    }
    if (envData["Email"] === "err") {
      setErro17("block")
    } else {
      setErro17("none")
    }
    if (envData["Telefone"] === "err") {
      setErro20("block")
    } else {
      setErro20("none")
    }
  }

  function getImgEnd(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setComprovanteEndereco(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }

  function getImgEndSoc(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setComprovanteEndSocio(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }

  function getImgCPF(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setComprovanteCpfCnpj(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }

  function getImgRGCNH(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setComprovanteRgCnh(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }

  function getImgRenda(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setComprovanteRenda(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }

  const [margin, setMargin] = useState(false)

  useEffect(() => {
    let teste = window.location.href.split("/")
    let params = teste[teste.length - 1].split(";")

    if (params.length > 5) {
      setSegmento(params[0])
      if (params[1] === "semanal") {
        setCiclo({
          semanal: true,
          mensal: false,
          quinzenal: false,
          sliderMin: 1,
          sliderMax: 12,
        })
      }
      if (params[1] === "quinzenal") {
        setCiclo({
          semanal: false,
          mensal: false,
          quinzenal: true,
          sliderMin: 1,
          sliderMax: 6,
        })
      }
      if (params[1] === "mensal") {
        setCiclo({
          semanal: false,
          mensal: true,
          quinzenal: false,
          sliderMin: 1,
          sliderMax: 3,
        })
      }
      setTitulo(params[2])
      setVezes(Number(params[3]))
      setValor(params[4])
      setNome(params[5].replace(/#/g," "))
      if (params[6].length<15){
        setCpf_cnpj(params[6])
      } else{
        setCnpj(params[6].replace("#","/"))
      }
      setEmail(params[7])
      setTelefone(params[8])
    }
    window.scrollTo(0,0)
    setMargin(document.getElementById("1").clientHeight + "px")
  }, [])


  function teleMask(v){
    v = v.replace(/\D/g, '')
    let t = v.length

    switch(t){
      default:
        if(t >= 11){
          v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{5})(\d{4})/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1')
          setTelefone(v)
        }
        break
      case 8:
        v = v.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 9:
        v = v.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 10:
        v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{4})(\d{4})/, '$1-$2')
        setTelefone(v)
        break
        
    }
    setTelefone(v)
  }
  function cpfMask(v, c){
    v = v.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
    switch(c){
      case 1:
        setCpf_cnpj(v)
        break
      case 2:
        setScpf1(v)
        break
      case 3:
        setScpf2(v)
        break
      case 4:
        setScpf3(v)
        break
      case 5:
        setCnpj(v)
        break
      default:
        console.log('algum erro de Switch na pagina Solicitar emprestimos')
    }
  }
  function cnpjMask(v){
    v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d{2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
    setCnpj(v)
  }

  function valorMask (v){
    v = v.replace(/\D/g, '')
    v = v.split('').reverse().join('')
    v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1,$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{3})/, '$1.$2').replace(/(-\d{3})\d+?$/, '$1')
    v = v.split('').reverse().join('')
    setRendimento(v)
  }

  //default return
  return (
    <ThemeProvider theme={theme}>
      <div className="wppFixed">
        <SocialIcon
          bgColor="#f39d09"
          fgColor="#fff"
          network="whatsapp"
          onClick={() => document.getElementById("wppSend").click()}
        />
        <a
          id="wppSend"
          style={{ display: "none" }}
          href="https://api.whatsapp.com/send?phone=558494418757&text=Gostaria%20de%20saber%20mais%20sobre%20os%20tipos%20de%20empr%C3%A9stimos."
        >{null}</a>
      </div>
      <Header id="1" marginbottom={margin}>
        <div style={{ padding: "0 10% 0 10%" }}>
          <Navbar />
        </div>
        <Content>
          <h2
            className="light"
            onClick={() => alert(document.getElementById("1").clientHeight)}
          >
            Solicitar empréstimo
          </h2>
          <h5 className="light" style={{textAlign: 'center'}}>Realize seu empréstimo conosco!</h5>
        </Content>
        <BgContainer img={bg2} speed="15s" height top="50%" />
        <BgContainer img={bg} speed="10s" height top="50%" />
      </Header>

      <ContainerMain>
        {/* pagina Main */}
        <ContainerFirst display={page === 1 ? "flex" : "none"}>
          <h4>Você é uma pessoa jurídica ou física?</h4>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => setPage(3)}
          >
            Jurídica
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => setPage(2)}
          >
            Física
          </Button>
        </ContainerFirst>

        {/* pagina de cpf  */}
        <Container display={page === 2 ? "flex" : "none"} id="cpfContent">
          <Button
            variant="contained"
            className={classes.btnBack}
            onClick={() => setPage(1)}
          >
            voltar
          </Button>

          <Classic>
            <AnimationOver active={animationOn}>
              <AnimationLoading
                src={loading}
                style={{
                  width: "150px",
                  height: "150px",
                  display: animationOn && !isShow ? "flex" : "none",
                }}
                isShow={!isShow}
              />
              <AnimationMsg isShow={isShow}>{msgUser}</AnimationMsg>
            </AnimationOver>
            <SideToSide>
              <p>Informações Pessoais</p>
              <TextField
                  error={erro18 === "block" ? true : false}
                  id="nome"
                  variant="outlined"
                  label={page === 2 ? "Nome" : "Razão social"}
                  size="small"
                  required
                  value={nome}
                  className={classes.root}
                  onChange={event => setNome(event.target.value)}
              ></TextField>
              <TextField
                id="endereco"
                variant="outlined"
                label="Endereço Residencial"
                size="small"
                required
                className={classes.root}
                onChange={event => setEndereco(event.target.value)}
                error={erro1 === "block" ? true : false}
              ></TextField>

              <TextField
                error={erro2 === "block" ? true : false}
                id="enderecoN"
                variant="outlined"
                label="Número"
                type="number"
                size="small"
                required
                className={classes.root}
                onChange={event => setEnderecoN(event.target.value)}
              ></TextField>

              <TextField
                id="endereco"
                variant="outlined"
                label="CEP"
                size="small"
                required
                className={classes.root}
                onChange={event => setCEP(event.target.value)}
                error={erro12 === "block" ? true : false}
              ></TextField>

              <TextField
                error={erro17 === "block" ? true : false}
                id="contato2"
                variant="outlined"
                label="Email"
                value={email}
                size="small"
                required
                className={classes.root}
                onChange={event => setEmail(event.target.value)}
              ></TextField>

              <TextField
                error={erro20 === "block" ? true : false}
                id="contato1"
                variant="outlined"
                label="Telefone"
                value={telefone}
                size="small"
                required
                className={classes.root}
                onChange={event => teleMask(event.target.value)}
              ></TextField>

              <input
                accept="image/*"
                id="comprovanteEndereco"
                multiple
                type="file"
                onChange={event => getImgEnd(event.target.files[0])}
              />
              <label htmlFor="comprovanteEndereco" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  component="span"
                  style={{ backgroundColor: erro3 === "block" ? "red" : "" }}
                  className={
                    comprovanteEndereco === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                >
                  Comprovante de endereço
                </Button>
              </label>

              <TextField
                error={erro4 === "block" ? true : false}
                id="cpfAdmin"
                variant="outlined"
                label={page === 2 ? "CPF" : "CPF do sócio administrador"}
                size="small"
                value={cpf_cnpj}
                required
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 1)}
              >
                {" "}
              </TextField>

              <input
                accept="image/*"
                multiple
                type="file"
                onChange={event => getImgCPF(event.target.files[0])}
              />
              <label htmlFor="comprovanteCPF" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: erro5 === "block" ? "red" : "" }}
                  className={
                    comprovanteCpfCnpj === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                >
                  Selfie com RG ou CNH
                </Button>
              </label>

              <input
                accept="image/*"
                multiple
                type="file"
                onChange={event => getImgRGCNH(event.target.files[0])}
              />
              <label htmlFor="rgCnh" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: erro6 === "block" ? "red" : "" }}
                  className={
                    comprovanteRgCnh === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                  onChange={event => setComprovanteRgCnh(event.target.value)}
                >
                  Verso RG ou CNH
                </Button>
              </label>

              <TextField
                error={erro7 === "block" ? true : false}
                variant="outlined"
                label="Rendimento mensal"
                size="small"
                value={rendimentoMensal}
                required
                className={classes.root}
                onChange={event => valorMask(event.target.value)}
              ></TextField>

              <input
                accept="image/*"
                multiple
                type="file"
                onChange={event => getImgRenda(event.target.files[0])}
              />
              <label htmlFor="rendaComprovante" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: erro8 === "block" ? "red" : "" }}
                  className={
                    comprovanteRenda === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                  onChange={event => setComprovanteRenda(event.target.value)}
                >
                  Comprovante de renda
                </Button>
              </label>

              <TextField
                error={erro14 === "block" ? true : false}
                variant="outlined"
                label="CPF devedor solidario 1 (Opcional)"
                size="small"
                value={scpf1}
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 2)}
              ></TextField>

              <TextField
                error={erro15 === "block" ? true : false}
                variant="outlined"
                label="CPF devedor solidario 2 (Opcional)"
                size="small"
                value={scpf2}
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 3)}
              ></TextField>

              <TextField
                error={erro16 === "block" ? true : false}
                variant="outlined"
                label="CPF devedor solidario 3 (Opcional)"
                size="small"
                value={scpf3}
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 4)}
              ></TextField>

              <h6>*campos obrigatórios</h6>
            </SideToSide>

            <SideToSide>
              <p>Informações de empréstimo</p>
              <SubContainer>
                <div>
                  <Slider
                    defaultValue={200}
                    aria-label={"Teste1111a"}
                    value={valor}
                    onChange={(evt,value)=>setValor(value)}
                    min={200}
                    max={3000}
                    step={200}
                    valueLabelDisplay="auto"
                    style={{
                      marginTop: "30px",
                      color: "#3380ce",
                    }}
                  />
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  <h6 style={{ marginTop: "30px" }}>
                    {valor === 3000 ? "R$ 1000+" : "R$ " + valor}
                  </h6>
                </div>
              </SubContainer>

              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ color: "#3380ce" }}
              >
                Selecione seu segmento
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                onChange={function (event) {
                  setSegmento(event.target.value)
                }}
                value={segmento}
                label="Selecione"
                className={classes.root}
              >
                <MenuItem value={"Comercio"}>Comércio</MenuItem>
                <MenuItem value={"Servicos"}>Serviços</MenuItem>
                <MenuItem value={"Industria"}>Indústria</MenuItem>
                <MenuItem value={"Outros"}>Outros</MenuItem>
              </Select>
              <p></p>
              <h6 style={{ color: erro11 === "block" ? "red" : "" }}>
                Selecione o ciclo de pagamento
              </h6>
              <ButtonContainerSoli>
                <Button
                  variant="contained"
                  style={{
                    margin: "10px",
                    backgroundColor: ciclo.semanal ? "#f39d09" : "#3380ce",
                    color: "white",
                  }}
                  width="100%"
                  height="50px"
                  onClick={function () {
                    setCiclo({
                      semanal: !ciclo.semanal,
                      mensal: false,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 12,
                    })
                    if (quantasVezes>3){
                      setVezes(3)
                    }
                  }}
                >
                  Semanal
                </Button>

                <Button
                  variant="contained"
                  width="100%"
                  height="50px"
                  style={{
                    margin: "10px",
                    backgroundColor: ciclo.quinzenal ? "#f39d09" : "#3380ce",
                    color: "white",
                  }}
                  onClick={function () {
                    setCiclo({
                      semanal: false,
                      mensal: false,
                      quinzenal: !ciclo.quinzenal,
                      sliderMin: 1,
                      sliderMax: 6,
                    })
                    if (quantasVezes>6){
                      setVezes(6)
                    }
                  }}
                >
                  Quinzenal
                </Button>

                <Button
                  variant="contained"
                  width="100%"
                  height="50px"
                  style={{
                    margin: "10px",
                    backgroundColor: ciclo.mensal ? "#f39d09" : "#3380ce",
                    color: "white",
                  }}
                  onClick={function () {
                    setCiclo({
                      semanal: false,
                      mensal: !ciclo.mensal,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 3,
                    })
                  }}
                >
                  Mensal
                </Button>
              </ButtonContainerSoli>

              <h6>Em quantas vezes ?</h6>
              <SubContainer>
                <div>
                  <Slider
                    defaultValue={1}
                    aria-label={"Teste"}
                    value={quantasVezes}
                    onChange={(event,value)=>setVezes(value)}
                    min={ciclo.sliderMin}
                    max={ciclo.sliderMax}
                    step={1}
                    valueLabelDisplay="auto"
                    style={{
                      margin: "30px auto 0px auto",
                      color: "#3380ce",
                      width: "100%",
                    }}
                  />
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  <h6 style={{ marginTop: "30px" }}>{quantasVezes + "X"}</h6>
                </div>
              </SubContainer>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ color: "#3380ce" }}
              >
                Qual tipo de garantia?
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={titulo}
                className={classes.root}
                onChange={function (event) {
                  setTitulo(event.target.value)
                  if (event.target.value === "GarantiaIntegral") {
                    alert(
                      "Usando garantia integral seu ciclo obrigatioriamente será mensal"
                    )
                    setCiclo({
                      semanal: false,
                      mensal: !ciclo.mensal,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 3,
                    })
                  }
                  setErro3("none")
                }}
                label="Selecione"
              >
                <MenuItem value={"SemGarantia"}>Sem garantia</MenuItem>
                <MenuItem value={"SemiGarantia"}>Semi garantia</MenuItem>
                <MenuItem value={"GarantiaParcial"}>Garantia parcial</MenuItem>
                <MenuItem value={"GarantiaIntegral"}>
                  Garantia integral
                </MenuItem>
              </Select>

              <Recaptcha
                elementID="recap-cpf"
                sitekey="6LfE8PQUAAAAAM-Ulcpiik6EHzqpjSj6knNN4-Kl"
                render="explicit"
                onloadCallback={()=>{}}
                verifyCallback={() => {setVerifyed(true)}}
                hl="pt-BR"
                size='compact'
              />
              <Snackbar open={erro21 === 'block'? true : false} autoHideDuration={6000} onClose={() => {setErro21('none')}}>
                <Alert onClose={() => {setErro21('none')}} severity="error">
                  verifique o reCAPTCHA!
                </Alert>
              </Snackbar>

              <Button
                className={classes.btnUP}
                onClick={() => sendRequest("1")}
              >
                Solicitar
              </Button>
            </SideToSide>
          </Classic>
        </Container>

        {/* pagina cnpj */}
        <Container display={page === 3 ? "flex" : "none"} id="cnpjContent">
          <Button
            variant="contained"
            onClick={() => setPage(1)}
            className={classes.btnBack}
          >
            voltar
          </Button>

          <Classic>
            <AnimationOver active={animationOn}>
              <AnimationLoading
                src={loading}
                style={{
                  widht: "200px",
                  heigth: "200px",
                  display: animationOn && !isShow ? "flex" : "none",
                }}
                isShow={!isShow}
              />
              <AnimationMsg isShow={isShow}>{msgUser}</AnimationMsg>
            </AnimationOver>
            <SideToSide>
              <p>Informações da empresa</p>
              <TextField
                error={erro18 === "block" ? true : false}
                variant="outlined"
                label={page === 2 ? "Nome" : "Razão social"}
                size="small"
                required
                value={nome}
                className={classes.root}
                onChange={event => setNome(event.target.value)}
              ></TextField>
              <TextField
                error={erro22 === "block" ? true : false}
                id="cnpj"
                variant="outlined"
                label={"CNPJ"}
                size="small"
                value={cnpj}
                required
                className={classes.root}
                onChange={event => cnpjMask(event.target.value)}
              >
                {" "}
              </TextField>
              <TextField
                error={erro1 === "block" ? true : false}
                variant="outlined"
                label="Endereço comercial"
                size="small"
                required
                className={classes.root}
                onChange={event => setEndereco(event.target.value)}
              ></TextField>

              <TextField
                error={erro2 === "block" ? true : false}
                variant="outlined"
                label="Número"
                type="number"
                size="small"
                required
                className={classes.root}
                onChange={event => setEnderecoN(event.target.value)}
              ></TextField>

              <TextField
                id="endereco"
                variant="outlined"
                label="CEP"
                size="small"
                required
                className={classes.root}
                onChange={event => setCEP(event.target.value)}
                error={erro12 === "block" ? true : false}
              ></TextField>

              <TextField
                error={erro17 === "block" ? true : false}
                id="contato"
                variant="outlined"
                label="Email"
                value={email}
                size="small"
                required
                className={classes.root}
                onChange={event => setEmail(event.target.value)}
              ></TextField>

              <TextField
                error={erro20 === "block" ? true : false}
                id="contato"
                variant="outlined"
                label="Telefone"
                value={telefone}
                size="small"
                required
                className={classes.root}
                onChange={event => teleMask(event.target.value)}
              ></TextField>

              <input
                accept="image/*"
                id="comprovanteEndereco"
                multiple
                type="file"
                onChange={event => getImgEnd(event.target.files[0])}
              />
              <label htmlFor="comprovanteEndereco" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: erro3 === "block" ? "red" : "" }}
                  className={
                    comprovanteEndereco === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                >
                  comprovante de endereço da empresa
                </Button>
              </label>

              <TextField
                error={erro4 === "block" ? true : false}
                variant="outlined"
                label="CPF socio administrador"
                size="small"
                value={cpf_cnpj}
                required
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 1)}
              >
                {" "}
              </TextField>

              <input
                accept="image/*"
                id="comprovanteEnderecoSoc"
                type="file"
                onChange={event => getImgEndSoc(event.target.files[0])}
              />
              <label htmlFor="comprovanteEnderecoSoc" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={
                    comprovanteEndSocio === "" ? classes.btn : classes.btnUP
                  }
                  style={{ backgroundColor: erro19 === "block" ? "red" : "" }}
                  startIcon={<CloudUploadIcon />}
                >
                  Comprovante de endereço do sócio ADM
                </Button>
              </label>

              <input
                accept="image/*"
                id="comprovanteCPF"
                multiple
                type="file"
                onChange={event => getImgCPF(event.target.files[0])}
              />
              <label htmlFor="comprovanteCPF" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: erro5 === "block" ? "red" : "" }}
                  className={
                    comprovanteCpfCnpj === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                >
                  Selfie com RG ou CNH do sócio ADM
                </Button>
              </label>

              <input
                accept="image/*"
                id="rgCnh"
                multiple
                type="file"
                onChange={event => getImgRGCNH(event.target.files[0])}
              />
              <label htmlFor="rgCnh" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{
                    backgroundColor: erro6 === "block" ? "red" : "",
                    marginTop: "10px",
                  }}
                  className={
                    comprovanteRgCnh === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                  onChange={event => setComprovanteRgCnh(event.target.value)}
                >
                  Verso rg ou cnh do sócio adm
                </Button>
              </label>

              <TextField
                error={erro7 === "block" ? true : false}
                id="rendimento"
                variant="outlined"
                label="Faturamento mensal"
                size="small"
                value={rendimentoMensal}
                required
                className={classes.root}
                onChange={event => valorMask(event.target.value)}
              />

              <input
                accept="image/*"
                id="rendaComprovante"
                multiple
                type="file"
                onChange={event => getImgRenda(event.target.files[0])}
              />
              <label htmlFor="rendaComprovante" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: erro8 === "block" ? "red" : "" }}
                  className={
                    comprovanteRenda === "" ? classes.btn : classes.btnUP
                  }
                  startIcon={<CloudUploadIcon />}
                  onChange={event => setComprovanteRenda(event.target.value)}
                >
                  Comprovante de faturamento
                </Button>
              </label>

              <TextField
                id="cpfS1"
                variant="outlined"
                label="CPF devedor solidario 1 (Opcional)"
                size="small"
                value={scpf1}
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 2)}
              ></TextField>

              <TextField
                id="cpfS2"
                variant="outlined"
                label="CPF devedor solidario 2 (Opcional)"
                size="small"
                className={classes.root}
                value={scpf2}
                onChange={event => cpfMask(event.target.value, 3)}
              ></TextField>

              <TextField
                id="cpfS3"
                variant="outlined"
                label="CPF devedor solidario 3 (Opcional)"
                size="small"
                value={scpf3}
                className={classes.root}
                onChange={event => cpfMask(event.target.value, 4)}
              ></TextField>

              <h6>*campos obrigatórios</h6>
            </SideToSide>

            <SideToSide>
              <p>Informações de empréstimo</p>
              <SubContainer>
                <div>
                  <Slider
                    defaultValue={200}
                    aria-label={"Teste"}
                    value={valor}
                    onChange={(evt,value)=>setValor(value)}
                    min={200}
                    max={3000}
                    step={200}
                    valueLabelDisplay="auto"
                    style={{
                      marginTop: "30px",
                      color: "#3380ce",
                    }}
                  />
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  <h6 style={{ marginTop: "30px" }}>
                    {valor === 3000 ? "R$ 3000+" : "R$ " + valor}
                  </h6>
                </div>
              </SubContainer>

              <InputLabel id="demo-simple-select-outlined-label">
                Selecione seu segmento
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                onChange={function (event) {
                  setSegmento(event.target.value)
                }}
                label="Selecione"
                className={classes.root}
                value={segmento}
              >
                <MenuItem value={"Comercio"}>Comércio</MenuItem>
                <MenuItem value={"Servicos"}>Serviços</MenuItem>
                <MenuItem value={"Industria"}>Industria</MenuItem>
                <MenuItem value={"Outros"}>Outros</MenuItem>
              </Select>
              <p></p>
              <h6>Selecione o ciclo de pagamento</h6>
              <ButtonContainerSoli>
                <Button
                  variant="contained"
                  style={{
                    margin: "10px",
                    backgroundColor: ciclo.semanal ? "#f39d09" : "#3380ce",
                    color: "white",
                  }}
                  width="100%"
                  height="50px"
                  onClick={function () {
                    if (quantasVezes>12){
                      setVezes(12)
                    }
                    setCiclo({
                      semanal: !ciclo.semanal,
                      mensal: false,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 12,
                    })
                  }}
                >
                  Semanal
                </Button>

                <Button
                  variant="contained"
                  width="100%"
                  height="50px"
                  style={{
                    margin: "10px",
                    backgroundColor: ciclo.quinzenal ? "#f39d09" : "#3380ce",
                    color: "white",
                  }}
                  onClick={function () {
                    setCiclo({
                      semanal: false,
                      mensal: false,
                      quinzenal: !ciclo.quinzenal,
                      sliderMin: 1,
                      sliderMax: 6,
                    })
                    if (quantasVezes>6){
                      setVezes(6)
                    }
                  }}
                >
                  Quinzenal
                </Button>

                <Button
                  variant="contained"
                  width="100%"
                  height="50px"
                  style={{
                    margin: "10px",
                    backgroundColor: ciclo.mensal ? "#f39d09" : "#3380ce",
                    color: "white",
                  }}
                  onClick={function () {
                    alert(
                      "Usando garantia integral seu ciclo obrigatioriamente será mensal"
                    )
                    setCiclo({
                      semanal: false,
                      mensal: !ciclo.mensal,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 3,
                    })
                    if (quantasVezes>3){
                      setVezes(3)
                    }
                  }}
                >
                  Mensal
                </Button>
              </ButtonContainerSoli>

              <h6>Em quantas vezes ?</h6>
              <SubContainer>
                <div>
                  <Slider
                    defaultValue={1}
                    aria-label={"Teste"}
                    value={quantasVezes}
                    onChange={(event,value)=>setVezes(value)}
                    min={ciclo.sliderMin}
                    max={ciclo.sliderMax}
                    step={1}
                    valueLabelDisplay="auto"
                    style={{
                      margin: "30px auto 0px auto",
                      color: "#3380ce",
                      width: "100%",
                    }}
                  />
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  <h6 style={{ marginTop: "30px" }}>{quantasVezes + "X"}</h6>
                </div>
              </SubContainer>

              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ color: "#3380ce" }}
              >
                Qual tipo de garantia?
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={titulo}
                className={classes.root}
                onChange={function (event) {
                  setTitulo(event.target.value)
                  if (event.target.value === "GarantiaIntegral") {
                    alert(
                      "Usando garantia integral seu ciclo obrigatioriamente será mensal"
                    )
                    setCiclo({
                      semanal: false,
                      mensal: !ciclo.mensal,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 3,
                    })
                    if (quantasVezes>3){
                      setVezes(3)
                    }
                  }
                  setErro3("none")
                }}
                label="Selecione"
              >
                <MenuItem value={"SemGarantia"}>Sem garantia</MenuItem>
                <MenuItem value={"SemiGarantia"}>Semi garantia</MenuItem>
                <MenuItem   value={"GarantiaParcial"}>Garantia parcial</MenuItem>
                <MenuItem value={"GarantiaIntegral"}>
                  Garantia integral
                </MenuItem>
              </Select>


              <Recaptcha
                elementID= 'recap-cnpj' 
                sitekey="6LfE8PQUAAAAAM-Ulcpiik6EHzqpjSj6knNN4-Kl"
                render="explicit"
                onloadCallback={()=>{}}
                verifyCallback={() => {setVerifyed(true)}}
                hl="pt-BR"
                size='compact'
              />
              <Snackbar open={erro21 === 'block'? true : false} autoHideDuration={6000} onClose={() => {setErro21('none')}}>
                <Alert onClose={() => {setErro21('none')}} severity="error">
                  verifique o reCAPTCHA!
                </Alert>
              </Snackbar>  
              
              <Button
                className={classes.btnUP}
                onClick={() => sendRequest("2")}
              >
                Solicitar
              </Button>              

            </SideToSide>
          </Classic>
        </Container>
      </ContainerMain>
      <Footer />
    </ThemeProvider>
  )
}

export default SolicitarEmprestimo
