import "./simulador.css"

import {
  AnimationLoading,
  AnimationMsg,
  AnimationOver,
  ButtonContainer,
  Container,
  NoOverlay,
  Overlay,
  ResultGrid,
  SubContainer
} from "./simulador.styles"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Snackbar,
  TextField,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core"
import React, { memo, useEffect, useRef, useState } from "react" //para o uso de hooks

import Alert from "@material-ui/lab/Alert"
import { Link } from "react-router-dom"
import Recaptcha from "react-recaptcha"
import { SocialIcon } from "react-social-icons"
import TaxasJson from "./taxas.json"
import loading from "../../images/loading.png"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3380ce",
    },
  },
})

function Simulador() {
  const [result, setResult] = useState("0%")
  const [quantoPrecisa, setQtprecisa] = useState("")
  const [quantasVezes, setVezes] = useState("")
  const [ciclo, setCiclo] = useState({
    semanal: false,
    quinzenal: false,
    mensal: false,
    sliderMax: 12,
    sliderMin: 1,
  })
  const [titulo, setTitulo] = useState("SemGarantia")
  const [segmento, setSegmento] = useState("Comercio")
  const [resultadoSim, setResultadoSim] = useState("??")

  const [nome, setNome] = useState("")
  const [cpf, setCpf] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [taxaUsada, setTaxaUsada] = useState("")
  const [parcelaPrice, setParcelaPrice] = useState("")

  const [erro2, setErro2] = useState("none")
  const [erro4, setErro4] = useState("none")
  const [erro5, setErro5] = useState("none")
  const [erro6, setErro6] = useState("none")
  const [erro7, setErro7] = useState("none")
  const [erro10, setErro10] = useState("none")
  const [verifyed, setVerifyed] = useState(false)
  const [iof, setIof] = useState('')
  const [iofValue, setIofValue] = useState('')

  const captcha = useRef(null)

  useEffect(()=>{
    captcha.current.reset()
    fetch("https://api.credtech.me/settings", {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      method: "GET",
    })
    .then(res => res.json())
    .then(res => setIof(res.IOF))
    .catch(err=>alert("erro contato"))
  },[])

  const [tabelaResposta, setTabelaResposta] = useState([
    <p key="#">#</p>,
    <p key="CampoParcelas">Parcela</p>,
    <p key="Saldo">Saldo devedor</p>,
  ])

  const [animationOn, setAnimationOn] = useState(false)

  function calculate() {
    let Taxas = TaxasJson
    let cicle
    let cicleDays
    let table = tabelaResposta

    if (ciclo.semanal) {
      cicle = "Semanal"
      cicleDays=7
      setTaxaUsada((Taxas[segmento][titulo][cicle]*100).toFixed(2)+"% a semana")
    }
    if (ciclo.quinzenal) {
      cicle = "Quinzenal"
      cicleDays=15
      setTaxaUsada((Taxas[segmento][titulo][cicle]*100).toFixed(2)+"% a quinzena")
    }
    if (ciclo.mensal) {
      cicle = "Mensal"
      cicleDays=30
      setTaxaUsada((Taxas[segmento][titulo][cicle]*100).toFixed(2)+"% ao mês")
    }

    let constante=(1+Taxas[segmento][titulo][cicle])**quantasVezes*Taxas[segmento][titulo][cicle]
    let constante2=(1+Taxas[segmento][titulo][cicle])**quantasVezes-1
    let parcela=quantoPrecisa*(constante/constante2)

    if (iof==='1'){
      let iof1=0
      let taxaDia
      if (cpf.length<15){
        taxaDia = 0.000082
      } else{
        taxaDia = 0.000041
      }

      let valor=Number(quantoPrecisa)
      let valorFinal=quantoPrecisa

      let juros
      let amort
      let iof2

      for (let i=1; i<=quantasVezes; i++){
        juros=valor*Taxas[segmento][titulo][cicle]
        amort=parcela-juros
        iof2=(amort*0.0038)+(amort*taxaDia*i*cicleDays)
        valor-=parcela-juros
        iof1+=iof2
      }

      iof1=(valorFinal*iof1)/(valorFinal-iof1)
      valorFinal=valorFinal+iof1

      parcela=valorFinal*(constante/constante2)

      setIofValue(iof1.toFixed(2))
    }
    
    let tot = parcela*Number(quantasVezes)

    for (let o = 0; o < Number(quantasVezes); o++) {
      table.push(<p key={o}>{o + 1}</p>)
      table.push(
        <p key={o + "-Parcela"} id={o + "-Parcela"}>
          R$ {parcela.toFixed(2)}
        </p>
      )
      table.push(
        <p key={o + "-Falta"}>
          R${" "}
          {(tot - (o+1) * parcela).toFixed(2)}
        </p>
      )
    }

    setParcelaPrice(parcela.toFixed(2))
    setResultadoSim(parseFloat(parcela*quantasVezes).toFixed(2))
    setTabelaResposta(table)
  }

  function verifyFields() {
    if (
      ciclo.semanal === false &&
      ciclo.quinzenal === false &&
      ciclo.mensal === false
    ) {
      setErro2("block")
    } else {
      setErro2("none")
    }
    if (
      !(
        segmento === undefined ||
        (ciclo.semanal === false &&
          ciclo.quinzenal === false &&
          ciclo.mensal === false) ||
        titulo === undefined
      )
    ) {
      setTimeout(() => setResult("100%"), 200)
      setAnimationOn(true)
      setTimeout(() => setAnimationOn(false), 200)
    }
  }

  function verifyPerson() {
    if (verifyed) {
      setAnimationOn(true)
      let cicle
      if (ciclo.semanal) {
        cicle = "Semanal"
      }
      if (ciclo.quinzenal) {
        cicle = "Quinzenal"
      }
      if (ciclo.mensal) {
        cicle = "Mensal"
      }

      let envData = {
        Nome: nome,
        CPF_CNPJ: cpf,
        Email: email,
        Telefone: telefone,
        Valor: quantoPrecisa,
        Segmento: segmento,
        Ciclo: cicle,
        Vezes: quantasVezes,
        Garantia: titulo,
      }

      fetch("https://api.credtech.me/simulador", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(envData),
      })
        .then(res => res.json())
        .then(res => verifyResponse(res))
        .then(()=>{
          setIsShow(true)
          setTimeout(() => setAnimationOn(false), 1000)
          setTimeout(() => setIsShow(false), 1200)
        })
        .catch(ex => console.log(ex))
    } else {
      setErro10("block")
    }
  }

  const qualGarantia = () =>{
    let garantiaQ
    if (titulo==='SemGarantia'){garantiaQ="Sem garantia"}
    if (titulo==='SemiGarantia'){garantiaQ="Semi-garantia"}
    if (titulo==='GarantiaParcial'){garantiaQ="Garantia parcial"}
    if (titulo==='GarantiaIntegral'){garantiaQ="Garantia integral"}
    return garantiaQ
  }

  function verifyResponse(envData) {
    if (envData["Nome"] === "err") {
      setErro4("block")
    } else {
      setErro4("none")
    }
    if (envData["CPF_CNPJ"] === "err") {
      setErro5("block")
    } else {
      setErro5("none")
    }
    if (envData["Email"] === "err") {
      setErro6("block")
    } else {
      setErro6("none")
    }
    if (envData["Telefone"] === "err") {
      setErro7("block")
    } else {
      setErro7("none")
    }
    if (
      envData["Nome"] !== "err" &&
      envData["CPF_CNPJ"] !== "err" &&
      envData["Email"] !== "err" &&
      envData["Telefone"] !== "err"
    ) {
      setMsgUser('Sucesso')
      calculate()
      setAnimationOn(true)
    } else{
      setMsgUser('Verifique os campos')
    }
  }

  var prepareQuerry = () => {
    if (resultadoSim === "??") {
      return "/"
    } else {
      let cicle
      if (ciclo.mensal) {
        cicle = "mensal"
      }
      if (ciclo.quinzenal) {
        cicle = "quinzenal"
      }
      if (ciclo.semanal) {
        cicle = "semanal"
      }
      return (
        "/solicitaremprestimo/" +
        segmento +
        ";" +
        cicle +
        ";" +
        titulo +
        ";" +
        quantasVezes +
        ";" +
        quantoPrecisa +
        ";" +
        nome.replace(" ","#") +
        ";" +
        cpf.replace("/","#") +
        ";" +
        email +
        ";" +
        telefone
      )
    }
  }

  const [isShow, setIsShow] = useState(false)
  const [msgUser, setMsgUser] = useState("Sucesso")

  function teleMask(v){
    v = v.replace(/\D/g, '')
    let t = v.length

    switch(t){
      default:
        if(t >= 11){
          v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{5})(\d{4})/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1')
          setTelefone(v)
        }
        break
      case 8:
        v = v.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 9:
        v = v.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 10:
        v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{4})(\d{4})/, '$1-$2')
        setTelefone(v)
        break
        
    }
    setTelefone(v)
  }
  function cpfMask(v, c){
    let t = v.replace(/\D/g, '').length
    if(t <= 11){
      v = v.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{2})/, '$1-$2')
    }else{
      v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d{2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
    }
    setCpf(v)
    
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <AnimationOver active={animationOn}>
            <AnimationLoading
              src={loading}
              style={{
                width: "150px",
                height: "150px",
                display: animationOn && !isShow ? "flex" : "none",
              }}
              isShow={!isShow}
            />
            <AnimationMsg isShow={isShow}>{msgUser}</AnimationMsg>
          </AnimationOver>
        <Overlay width={result} padding={result === "100%" ? "10%" : "0%"}>
          <h5
            onClick={event => {
              setErro4("none")
              setErro5("none")
              setErro6("none")
              setTabelaResposta([
                <p>#</p>,
                <p>Parcela</p>,
                <p>Saldo devedor</p>,
              ])
              setResultadoSim("??")
              setTimeout(() => setResult("0%"), 200)
              setAnimationOn(true)
              setTimeout(() => setAnimationOn(false), 200)
            }}
          >
            X
          </h5>
          <h4>Só mais alguns dados e te mostramos o resultado!</h4>
          <TextField
            error={erro4 === "block" ? true : false}
            label="Razão social ou Nome"
            variant="outlined"
            style={{ marginTop: "20px" }}
            onChange={event => setNome(event.target.value)}
          />
          <TextField
            label="CNPJ ou CPF"
            variant="outlined"
            value={cpf}
            style={{ marginTop: "20px" }}
            onChange={event => cpfMask(event.target.value)}
            error={erro5 === "block" ? true : false}
          />
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            style={{ marginTop: "20px" }}
            onChange={event => setEmail(event.target.value)}
            error={erro6 === "block" ? true : false}
          />
          <TextField
            label="Telefone"
            variant="outlined"
            value={telefone}
            style={{ marginTop: "20px" }}
            onChange={event => teleMask(event.target.value)}
            error={erro7 === "block" ? true : false}
          />
          <ResultGrid
            display={
              resultadoSim !== "??" && result === "100%" ? "grid" : "none"
            }
            rows={quantasVezes + 1}
          >
            {tabelaResposta.map(el => el)}
          </ResultGrid>
          <h5 style={{ textAlign: "left", marginTop: "20px" }}>
            Valor solicitado: R$ {resultadoSim !== "??" ? quantoPrecisa : "??"}
          </h5>
          <h5 style={{ textAlign: "left", marginTop: "10px" }} id="parcela">
            Valor de cada parcela: R${" "}
            {resultadoSim !== "??" ? parcelaPrice : "??"}
          </h5>
          <h5 style={{ textAlign: "left", marginTop: "10px" }}>
            Quantidade de parcelas:{" "}
            {resultadoSim !== "??" ? quantasVezes : "??"}
          </h5>
          <h5 style={{ textAlign: "left", marginTop: "10px", display: iof==='1' && result === "100%"? 'block' : 'none' }}>
            Valor total do IOF:{" "}
            {resultadoSim !== "??" ? "R$ "+iofValue : "??"}
          </h5>
          <h5 style={{ textAlign: "left", marginTop: "10px", display: iof==='1' && result === "100%"? 'block' : 'none' }}>
            Tipo de garantia:{" "}
            {resultadoSim !== "??" ? qualGarantia() : "??"}
          </h5>
          <h5 style={{ textAlign: "left", marginTop: "10px" }}>
            Taxa usada:{" "}
            {resultadoSim !== "??" ? taxaUsada : "??"}
          </h5>
          <h5 style={{ textAlign: "left", marginTop: "10px" }} id="parcela">
            Totalizando: R${" "}
            {resultadoSim !== "??" ? (parcelaPrice*quantasVezes).toFixed(2) : "??"}
          </h5>
          <a id="wppSim" href='https://api.whatsapp.com/send?text=' style={{ display: "none" }}>{null}</a>
          <div style={{display: result === "100%" ? "block" : "none",width:'fit-content', margin:' 10px auto'}}>
            <Recaptcha
              elementID="recap-simulador"
              sitekey="6LfE8PQUAAAAAM-Ulcpiik6EHzqpjSj6knNN4-Kl"
              render="explicit"
              onloadCallback={()=>{}}
              verifyCallback={() => {
                setVerifyed(true)
              }}
              hl="pt-BR"
              size="compact"
              ref={captcha}
            />
            <Snackbar
              open={erro10 === "block" ? true : false}
              autoHideDuration={6000}
              onClose={() => {
                setErro10("none")
              }}
            >
              <Alert
                onClose={() => {
                  setErro10("none")
                }}
                severity="error"
              >
                verifique o reCAPTCHA!
              </Alert>
            </Snackbar>
          </div>
          <Link to={prepareQuerry} style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#f39d09",
                color: "#fff",
                width: "100%",
                marginTop: "10px",
              }}
              onClick={() => verifyPerson()}
            >
              {resultadoSim === "??" ? "Me mostre!" : "Solicitar agora!"}
            </Button>
          </Link>
          <Button
            style={{
              backgroundColor: "#34af23",
              color: "#fff",
              marginTop: "10px",
              display: resultadoSim === "??" ? "none" : "block",
            }}
            onClick={() => {
              let ioof= iof==='1' ? "\nPagarei de IOF: R$ "+iofValue : ''
              let a =
                "Minha simulação na CredTech! \nPegarei emprestado: R$ " +
                Number(quantoPrecisa).toFixed(2) +
                "\nCom parcelas de: R$ "+parcelaPrice+
                "\nPagas em: "+quantasVezes+" vezes"+
                ioof+
                "\nTipo de garantia: "+qualGarantia()+
                "\nA taxa será de: "+taxaUsada+
                "\nTotalizando: R$"+(parcelaPrice*quantasVezes).toFixed(2)

              a = window.encodeURI(a)

              document.getElementById("wppSim").href =
                "https://api.whatsapp.com/send?text=" + a
              document.getElementById("wppSim").click()
            }}
          >
            <SocialIcon bgColor="#34af23" fgColor="#fff" network="whatsapp" />
            Compartilhe o resultado no WhatsApp
          </Button>
        </Overlay>
        <NoOverlay display={result === "100%" ? "none" : "block"}>
          <div>
            <h4 style={{ textAlign: "center", marginBottom: "10px" }}>
              Simulador
            </h4>
            <h5>De quanto você precisa?</h5>
            <SubContainer>
              <div>
                <Slider //Aqui é como funciona o slider
                  defaultValue={200} //Valor padrão
                  aria-label={"Teste"}
                  getAriaValueText={value => setQtprecisa(value)} //Aqui é o esquema que sempre quando ele muda altera o valor de quantoPrecisa(linha34)
                  min={200}
                  max={3000}
                  step={200}
                  valueLabelDisplay="auto"
                  style={{
                    marginTop: "30px",
                    color: "#3380ce",
                  }}
                />
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                <h6 style={{ marginTop: "30px" }}>
                  {quantoPrecisa === 3000 ? "R$ 3000+" : "R$ " + quantoPrecisa}
                </h6>
              </div>
            </SubContainer>
          </div>
          <div>
            <h5>Qual seu segmento de atuação?</h5>
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Selecione
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label" //Ambos estilos, n precisa alterar
                id="demo-simple-select-outlined" //Ambos estilos, n precisa alterar
                value={segmento} //Aqui é a seleção dele corresponde, no caso segmento(linha 38)
                onChange={function (event) {
                  setSegmento(event.target.value) //Ao alterar o valor ele vai setar um novo na variavél q falei acima
                }}
                label="Selecione" //Aqui o valor inicial
              >
                <MenuItem value={"Comercio"}>Comércio</MenuItem>
                <MenuItem value={"Servicos"}>Serviços</MenuItem>
                <MenuItem value={"Industria"}>Indústria</MenuItem>
                <MenuItem value={"Outros"}>Outros</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <h5 style={{ color: erro2 === "block" ? "red" : "" }}>
              Qual ciclo de pagamento?
            </h5>
            <ButtonContainer className="mobileButtonCorrection">
              <Button //Os botão de ciclo fiz manualmente ent ficou mais complexo, recomendo copiar o Button-
                style={{
                  backgroundColor: ciclo.semanal ? "#f39d09" : "#3380ce",
                  width: "100%",
                  color: "#fff",
                }} //Container e colar la, junto com ele a variavel ciclo (linha 36)
                onClick={function () {
                  if (titulo !== "GarantiaIntegral") {
                    setCiclo({
                      semanal: !ciclo.semanal,
                      mensal: false,
                      quinzenal: false,
                      sliderMin: 1,
                      sliderMax: 12,
                    })
                  } else {
                    alert(
                      "Usando garantia integral seu ciclo obrigatioriamente será mensal"
                    )
                  }
                  setErro2("none")
                }}
              >
                Semanal
              </Button>
              <Button
                style={{
                  backgroundColor: ciclo.quinzenal ? "#f39d09" : "#3380ce",
                  width: "100%",
                  color: "#fff",
                }}
                onClick={function () {
                  if (titulo !== "GarantiaIntegral") {
                    setCiclo({
                      semanal: false,
                      mensal: false,
                      quinzenal: !ciclo.quinzenal,
                      sliderMin: 1,
                      sliderMax: 6,
                    })
                  } else {
                    alert(
                      "Usando garantia integral seu ciclo obrigatioriamente será mensal"
                    )
                  }
                  setErro2("none")
                }}
              >
                Quinzenal
              </Button>
              <Button
                style={{
                  backgroundColor: ciclo.mensal ? "#f39d09" : "#3380ce",
                  width: "100%",
                  color: "#fff",
                }}
                onClick={function () {
                  setCiclo({
                    semanal: false,
                    mensal: !ciclo.mensal,
                    quinzenal: false,
                    sliderMin: 1,
                    sliderMax: 3,
                  })
                  setErro2("none")
                }}
              >
                Mensal
              </Button>
            </ButtonContainer>
          </div>
          <div>
            <h5>Em quantas vezes?</h5>
            <SubContainer>
              <div>
                <Slider
                  defaultValue={1}
                  aria-label={"Teste"}
                  getAriaValueText={value => setVezes(value)}
                  min={ciclo.sliderMin}
                  max={ciclo.sliderMax}
                  step={1}
                  valueLabelDisplay="auto"
                  style={{
                    marginTop: "30px",
                    color: "#3380ce",
                  }}
                />
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                <h6 style={{ marginTop: "30px" }}>{quantasVezes + "x"}</h6>
              </div>
            </SubContainer>
          </div>
          <div>
            <h5>Qual tipo de garantia?</h5>
            <div>
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "10px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Selecione
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={titulo}
                  onChange={function (event) {
                    setTitulo(event.target.value)
                    if (event.target.value === "GarantiaIntegral") {
                      alert(
                        "Usando garantia integral seu ciclo obrigatioriamente será mensal"
                      )
                      setCiclo({
                        semanal: false,
                        mensal: !ciclo.mensal,
                        quinzenal: false,
                        sliderMin: 1,
                        sliderMax: 3,
                      })
                    }
                  }}
                  label="Selecione"
                >
                  <MenuItem value={"SemGarantia"}>Sem garantia</MenuItem>
                  <MenuItem value={"SemiGarantia"}>Semi garantia</MenuItem>
                  <MenuItem value={"GarantiaParcial"}>
                    Garantia parcial
                  </MenuItem>
                  <MenuItem value={"GarantiaIntegral"}>
                    Garantia integral
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#f39d09",
                color: "#fff",
                width: "100%",
                marginTop: "10px",
              }}
              onClick={() => verifyFields()}
            >
              Simular
            </Button>
          </div>
        </NoOverlay>
      </Container>
    </ThemeProvider>
  )
}

export default memo(Simulador)
