import './des_tit.css'

import {
  AnimationLoading,
  AnimationMsg,
  AnimationOver,
} from "../../components/simuladorv2/simulador.styles"
import { Container, Form } from "./des_tit.styles"
import { Content, Header } from "../faq/faq.styles"
import {
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import Alert from '@material-ui/lab/Alert';
import { BgContainer } from "../../components/header/header.styles"
import Button from "@material-ui/core/Button"
import Calendar from 'react-calendar'
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import Footer from "../../components/footer/footer"
import Navbar from "../../components/navbar/navbar"
import Recaptcha from 'react-recaptcha'
import { SocialIcon } from "react-social-icons"
import bg from "../../images/bg.png"
import bg2 from "../../images/bg2.png"
import img from "../../images/emprestimo.png"
import loading from "../../images/loading.png"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3380ce",
    },
  },
})

const useStyles = makeStyles({
  btn: {
    width: "100%",
    backgroundColor: "#3380ce",
    color: "#fff",
    margin: "0px auto 20px auto",
    "&:hover": {
      backgroundColor: "#1a4e82",
    },
  },
  btnUP: {
    backgroundColor: "#f39d09",
    width: "100%",
    color: "#fff",
    margin: "0px auto 20px auto",
    "&:hover": {
      backgroundColor: "#f39000",
    },
  },
})

function DescontoDeTitulos() {
  const [margin, setMargin] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setMargin(document.getElementById("1").clientHeight + "px")
  }, [])

  const [nome, setNome] = useState("")
  const [cpf_cnpj, setCpf_cnpj] = useState("")
  const [endereco, setEndereco] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [chequeFrente, setChequeFrente] = useState("")
  const [chequeVerso, setChequeVerso] = useState("")
  const [valor, setValor] = useState('')
  const [dataCheque, setDataCheque] = useState(new Date(new Date().getTime()+86400000*7))
  const [CEP, setCEP] = useState("")
  const [garantia, setGarantia] = useState("SemGarantia")
  //log de erros
  const [erro1, setErro1] = useState("none")
  const [erro2, setErro2] = useState("none")
  const [erro3, setErro3] = useState("none")
  const [erro4, setErro4] = useState("none")
  const [erro5, setErro5] = useState("none")
  const [erro6, setErro6] = useState("none")
  const [erro8, setErro8] = useState("none")
  const [erro9, setErro9] = useState("none")
  const [erro7, setErro7] = useState("none")
  const [erro10, setErro10] = useState("none")
  const [erro11, setErro11] = useState("none")

  const [animationOn, setAnimationOn] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [msgUser, setMsgUser] = useState("Sucesso")

  const [verifyed, setVerifyed] = useState(false)

  function sendRequest() {
    if(verifyed){     
      setAnimationOn(true)
      let envData = {
      Nome: nome,
      CPF_CNPJ: cpf_cnpj,
      Endereco: endereco,
      CEP: CEP,
      Email: email,
      Telefone: telefone,
      FotoF: chequeFrente,
      FotoT: chequeVerso,
      Valor: valor,
      DataCheque: dataCheque.getDate()+"/"+(dataCheque.getMonth()+1)+"/"+dataCheque.getFullYear(),
      Garantia: garantia,
      }
      
      fetch("https://api.credtech.me/desTit", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(envData),
      })
        .then(res => res.json())
        .then(res => verifyResponse(res))
        .catch(() => setMsgUser("Erro de conexão"))
        .finally(() => {
          setIsShow(true)
          setTimeout(() => setAnimationOn(false), 1000)
          setTimeout(() => setIsShow(false), 1200)
        })
    }else{
      setErro10('block')
    }
  }

  function verifyResponse(envData) {
    if (envData["Nome"] === "err") {
      setErro1("block")
    } else {
      setErro1("")
    }
    if (envData["CPF_CNPJ"] === "err") {
      setErro2("block")
    } else {
      setErro2("")
    }
    if (envData["Endereco"] === "err") {
      setErro3("block")
    } else {
      setErro3("none")
    }
    if (envData["FotoF"] === "err") {
      setErro4("block")
    } else {
      setErro4("none")
    }
    if (envData["FotoT"] === "err") {
      setErro5("block")
    } else {
      setErro5("none")
    }
    if (envData["Valor"] === "err") {
      setErro6("block")
    } else {
      setErro6("none")
    }
    if (envData["Email"] === "err") {
      setErro8("block")
    } else {
      setErro8("none")
    }
    if (envData["Telefone"] === "err") {
      setErro9("block")
    } else {
      setErro9("none")
    }
    if (envData["CEP"] === "err") {
      setErro7("block")
    } else {
      setErro7("none")
    }
    if (envData["DataCheque"] === "err") {
      setErro11("block")
    } else {
      setErro11("none")
    }
    if (envData["Res"] === "true") {
      setMsgUser("Sucesso")
    }
    if (envData["Res"] === "false") {
      setMsgUser("Confira os campos e tente novamente")
    }
  }

  function getImgFrente(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setChequeFrente(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }
  function getImgVerso(img) {
    const reader = new FileReader()

    reader.addEventListener("load", function () {
      setChequeVerso(this.result) //Esse valor (this.result) é o que vc precisa armazenar no json para enviar
    })

    reader.readAsDataURL(img)
  }

  function teleMask(v){
    v = v.replace(/\D/g, '')
    let t = v.length

    switch(t){
      default:
        if(t >= 11){
          v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{5})(\d{4})/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1')
          setTelefone(v)
        }
        break
      case 8:
        v = v.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 9:
        v = v.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
        setTelefone(v)
        break
      case 10:
        v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d{4})(\d{4})/, '$1-$2')
        setTelefone(v)
        break
        
    }
    setTelefone(v)
  }
  function cpfMask(v){
    let t = v.replace(/\D/g, '').length
    if(t <= 11){
      v = v.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{2})/, '$1-$2')
    }else{
      v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d{2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
    }
    setCpf_cnpj(v)
    
    
  }
  function valorMask (v){
    v = v.replace(/\D/g, '')
    v = v.split('').reverse().join('')
    v = v.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1,$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{3})/, '$1.$2').replace(/(-\d{3})\d+?$/, '$1')
    v = v.split('').reverse().join('')
    setValor(v)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="wppFixed">
        <SocialIcon
          bgColor="#f39d09"
          fgColor="#fff"
          network="whatsapp"
          onClick={() => document.getElementById("wppSend").click()}
        />
        <a
          id="wppSend"
          style={{ display: "none" }}
          href="https://api.whatsapp.com/send?phone=558494418757&text=Gostaria%20de%20saber%20mais%20sobre%20os%20tipos%20de%20empr%C3%A9stimos."
        >{null}</a>
      </div>
      <Header id="1" marginbottom={margin}>
        <div style={{ padding: "0 10% 0 10%" }}>
          <Navbar />
        </div>
        <Content>
          <h2
            className="light"
            onClick={() => alert(document.getElementById("1").clientHeight)}
          >
            Trocar cheques
          </h2>
          <h5 className="light" style={{textAlign: 'center'}}>
            Desconte seus títulos conosco. Insira os dados e solicite sua
            análise de crédito.
          </h5>
        </Content>
        <BgContainer img={bg2} speed="13s" height top="50%" />
        <BgContainer img={bg} speed="10s" height top="50%" />
      </Header>

      <Container>
        <Form>
          <AnimationOver active={animationOn}>
            <AnimationLoading
              src={loading}
              style={{
                width: "150px",
                height: "150px",
                display: animationOn && !isShow ? "flex" : "none",
              }}
              isShow={!isShow}
            />
            <AnimationMsg isShow={isShow}>{msgUser}</AnimationMsg>
          </AnimationOver>
          <h3>Insira os dados do cheque</h3>
          <TextField
            id="nome"
            variant="outlined"
            label="Razão social ou nome"
            size="small"
            onChange={event => setNome(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro1 === "block" ? true : false}
          />

          <TextField
            id="cpf_cnpj"
            variant="outlined"
            label="CPF ou CNPJ"
            size="small"
            value={cpf_cnpj}
            onChange={event => cpfMask(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro2 === "block" ? true : false}
          />

          <TextField
            id="endereco"
            variant="outlined"
            label="Endereço residencial ou comercial"
            size="small"
            onChange={event => setEndereco(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro3 === "block" ? true : false}
          />

          <TextField
            id="endereco"
            variant="outlined"
            label="CEP"
            size="small"
            onChange={event => setCEP(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro7 === "block" ? true : false}
          />

          <TextField
            id="Email"
            variant="outlined"
            label="Email"
            size="small"
            onChange={event => setEmail(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro8 === "block" ? true : false}
          />

          <TextField
            id="Telefone"
            variant="outlined"
            label="Telefone"
            size="small"
            value={telefone}
            onChange={event => teleMask(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro9 === "block" ? true : false}
          />

          <input
            accept="image/*"
            id="chequefrente"
            multiple
            type="file"
            onChange={event => getImgFrente(event.target.files[0])}
          />
          <label htmlFor="chequefrente">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
              className={chequeFrente === "" ? classes.btn : classes.btnUP}
              style={{
                width: "100%",
                marginBottom: "20px",
                backgroundColor: erro4 === "block" ? "red" : "",
              }}
            >
              Foto da frente do Cheque
            </Button>
          </label>

          <input
            accept="image/*"
            id="chequeverso"
            multiple
            type="file"
            onChange={event => getImgVerso(event.target.files[0])}
          />
          <label htmlFor="chequeverso">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
              className={chequeVerso === "" ? classes.btn : classes.btnUP}
              style={{
                width: "100%",
                marginBottom: "20px",
                backgroundColor: erro5 === "block" ? "red" : "",
              }}
            >
              Foto do verso do Cheque
            </Button>
          </label>

          <TextField
            id="valor"
            variant="outlined"
            label="Valor do cheque"
            value = {valor}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            size="small"
            onChange={event => valorMask(event.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
            error={erro6 === "block" ? true : false}
          />
          
          <h5
            style={{ color: erro11==='block' ? "red" : "#3380ce", marginBottom: "10px"}}
          >
            Pré-datado para:
          </h5>
          <Calendar
            onChange={data => {
              setDataCheque(data)
            }}
            value={dataCheque}
            maxDate={new Date(new Date().getTime()+86400000*90)}
            minDate={new Date(new Date().getTime()+86400000*7)}
            tileClassName={'calendarTyle'}
          />
          <p style={{ marginTop: "10px"}}>Escolha uma data futura de no mínimo 7 dias e no máximo 90 dias.</p>

          <h5
            style={{ color: "#3380ce", marginBottom: "20px", marginTop: '20px' }}
          >
            Qual o tipo de garantia?
          </h5>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={garantia}
            onChange={function (event) {
              setGarantia(event.target.value)
            }}
            label="Selecione"
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <MenuItem value={"SemGarantia"}>Sem garantia</MenuItem>
            <MenuItem value={"SemiGarantia"}>Semi garantia</MenuItem>
            <MenuItem value={"GarantiaParcial"}>Garantia parcial</MenuItem>
            <MenuItem value={"GarantiaIntegral"}>Garantia integral</MenuItem>
          </Select>

          <div style={{width:'fit-content', margin:' 10px auto'}}>
            <Recaptcha
              elementID='recap-des-tit'
              sitekey="6LfE8PQUAAAAAM-Ulcpiik6EHzqpjSj6knNN4-Kl"
              render="explicit"
              onloadCallback={()=>{}}
              verifyCallback={() => {setVerifyed(true)}}
              hl="pt-BR"
              size='compact'
            />
            <Snackbar open={erro10 === 'block'? true : false} autoHideDuration={6000} onClose={() => {setErro10('none')}}>
              <Alert onClose={() => {setErro10('none')}} severity="error">
                verifique o reCAPTCHA!
              </Alert>
            </Snackbar>
          </div>

          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#f39d09", marginbottom:'10px'}}
            onClick={() => {
              sendRequest()
            }}
          >
            Solicitar análise de crédito
          </Button>
        </Form>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="" src={img} style={{ width: "100%" }} />
        </div>
      </Container>
      <Footer />
    </ThemeProvider>
  )
}

export default DescontoDeTitulos
