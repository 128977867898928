import React from 'react'
import ScrollAnimation from "react-animate-on-scroll"
import anfac from '../../images/anfac.png'
import f5 from '../../images/f5.png'
import sebrae from '../../images/sebrae.png'
import serasa from '../../images/serasa.png'
import styled from 'styled-components'

export const Grid4Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: 900px) {
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr;
    }
`;

const ParceirosImg = styled.img` 
    width: 100%;
    filter: grayscale(100);
    transition: 0.2s;

    :hover{
        filter: grayscale(0)
    }
`

export default function Parceiros(){
    return(
        <Grid4Container>
            <ScrollAnimation animateIn="bounceInUp" duration={1} animateOnce={true}>
                <ParceirosImg src={sebrae}/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInUp" duration={1.5} animateOnce={true}>
                <ParceirosImg src={anfac}/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInUp" duration={2} animateOnce={true}>
                <ParceirosImg src={serasa}/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInUp" duration={1} animateOnce={true}>
                <ParceirosImg src={f5}/>
            </ScrollAnimation>
        </Grid4Container>
    )
}
