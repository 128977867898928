import styled,{keyframes} from 'styled-components'

function oscilate (props) {
    return keyframes`
        from{
            transform: translateY(${props.ymin})
        }
        to{
            transform: translateY(${props.ymax})
        }
    `
  }

export const AnimatedDiv = styled.div`
    position: absolute;
    top: ${props=>props.top};
    left: ${props=>props.left};
    animation: ${props=>props.time} ${props=> oscilate(props)} infinite alternate;
    z-index: ${props=>props.zIndex};

    @keyframes animation{
        from{
            transform: translateY(${props=>props.ymin})
        }
        to{
            transform: translateY(${props=>props.ymax})
        }
    }
`;

export const AnimateBall = styled(AnimatedDiv)`
    background-color: ${props=>props.light ? '#fff' : ''};
    border-radius: 100%;
    width: ${props=>props.size};
    height: ${props=>props.size};
`