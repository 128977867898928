import './taxas.css'

import {Content, Header} from '../faq/faq.styles'
import {FormControl, InputLabel, MenuItem, Select, ThemeProvider, createMuiTheme} from '@material-ui/core'
import React,{useEffect, useState} from 'react';

import {BgContainer} from '../../components/header/header.styles'
import Elo from '../../images/elo.png'
import Footer from '../../components/footer/footer'
import Hiper from '../../images/hiper.png'
import Navbar from '../../components/navbar/navbar'
import { SocialIcon } from "react-social-icons"
import Visa from '../../images/visa.png'
import bg from '../../images/bg.png'
import bg2 from '../../images/bg2.png'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3380ce"
    }
  },
});

function Taxas() {
  const [segmento, setSegmento] = useState('Serviços')

  const [margin,setMargin] = useState(false)

  useEffect(()=> {
    setMargin(document.getElementById('1').clientHeight+"px")
  },[])

  return (
    <div className='bg'>
      <div className="wppFixed">
        <SocialIcon
          bgColor="#f39d09"
          fgColor="#fff"
          network="whatsapp"
          onClick={() => document.getElementById("wppSend").click()}
        />
        <a
          id="wppSend"
          style={{ display: "none" }}
          href="https://api.whatsapp.com/send?phone=558494418757&text=Gostaria%20de%20saber%20mais%20sobre%20os%20tipos%20de%20empr%C3%A9stimos."
        >{null}</a>
      </div>
      <Header id='1' marginbottom={margin}>
        <div style={{padding: "0 10% 0 10%"}}>
          <Navbar/>
        </div>
        <Content>
          <h2 className='light'>TAXAS</h2>
          <h5 className='light' style={{textAlign: 'center'}}>Conheça nossas taxas!</h5>
        </Content>
        <BgContainer img={bg2} speed='13s' height top='50%'/>
        <BgContainer img={bg} speed='10s' height top='50%'/>
      </Header>
      <section className='taxs'>
        <ThemeProvider theme={theme}>
          <h5 style={{marginBottom: '10px'}}>Qual seu segmento de atuação?</h5>
          <FormControl variant="outlined" style={{ width: "100%"}}>
              <InputLabel id="demo-simple-select-outlined-label">Selecione</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={segmento}
                onChange={event=>setSegmento(event.target.value)}
                label="Selecione"
              >
              <MenuItem value={'Comércio'}>Comércio</MenuItem>
              <MenuItem value={'Serviços'}>Serviços</MenuItem>
              <MenuItem value={'Industria'}>Indústria</MenuItem>
              <MenuItem value={'Outros'}>Outros</MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
        <table className='tablecards segmentos' style={{display: segmento==='Serviços' || segmento==='Outros' ? "table" : "none"}}>
          <thead>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th colSpan="7" style={{fontSize: '14px'}}>Empréstimo ou capital de giro</th>
            </tr>
            <tr>
              <th colSpan='2' style={{color: '#2d75be'}}>Planos de pagamento</th>
              <th style={{color: '#2d75be'}}>Sem garantia</th>
              <th style={{color: '#2d75be'}}>Semi-garantia</th>
              <th style={{color: '#2d75be'}}>Garantia parcial</th>
              <th style={{color: '#2d75be'}}>Garantia integral</th>
              <th style={{color: '#2d75be'}}>Vezes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{color: '#2d75be', textOrientation: 'vertical'}} rowSpan='3'>Ciclo</th>
              <td>Semanal</td>
              <td>1.70% a.s</td>
              <td>1.50% a.s</td>
              <td>1.15% a.s</td>
              <td>Não se aplica</td>
              <td>12x</td>
            </tr>
            <tr>
              <td>Quinzenal</td>
              <td>3.90% a.q</td>
              <td>3.50% a.q</td>
              <td>2.48% a.q</td>
              <td>Não se aplica</td>
              <td>6x</td>
            </tr>
            <tr>
              <td>Mensal</td>
              <td>9.80% a.m</td>
              <td>8.50% a.m</td>
              <td>5.90% a.m</td>
              <td>4.99% a.m</td>
              <td>3x</td>
            </tr>
          </tbody>
        </table>
        <table className='tablecards segmentos' style={{display: segmento==='Comércio' ? "table" : "none"}}>
          <thead>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th colSpan="7" style={{fontSize: '14px'}}>Empréstimo ou capital de giro</th>
            </tr>
            <tr>
              <th colSpan='2' style={{color: '#2d75be'}}>Planos de pagamento</th>
              <th style={{color: '#2d75be'}}>Sem garantia</th>
              <th style={{color: '#2d75be'}}>Semi-garantia</th>
              <th style={{color: '#2d75be'}}>Garantia parcial</th>
              <th style={{color: '#2d75be'}}>Garantia integral</th>
              <th style={{color: '#2d75be'}}>Vezes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{color: '#2d75be'}} rowSpan='3'>Ciclo</th>
              <td>Semanal</td>
              <td>1.45% a.s</td>
              <td>1.3% a.s</td>
              <td>0.99% a.s</td>
              <td>Não se aplica</td>
              <td>12x</td>
            </tr>
            <tr>
              <td>Quinzenal</td>
              <td>3.40% a.q</td>
              <td>3.25% a.q</td>
              <td>2.25% a.q</td>
              <td>Não se aplica</td>
              <td>6x</td>
            </tr>
            <tr>
              <td>Mensal</td>
              <td>8.80% a.m</td>
              <td>7.50% a.m</td>
              <td>5.50% a.m</td>
              <td>4.50% a.m</td>
              <td>3x</td>
            </tr>
          </tbody>
        </table>
        <table className='tablecards segmentos' style={{display: segmento==='Industria' ? "table" : "none"}}>
          <thead>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th colSpan="7" style={{fontSize: '14px'}}>Empréstimo ou capital de giro</th>
            </tr>
            <tr>
              <th colSpan='2' style={{color: '#2d75be'}}>Planos de pagamento</th>
              <th style={{color: '#2d75be'}}>Sem garantia</th>
              <th style={{color: '#2d75be'}}>Semi-garantia</th>
              <th style={{color: '#2d75be'}}>Garantia parcial</th>
              <th style={{color: '#2d75be'}}>Garantia integral</th>
              <th style={{color: '#2d75be'}}>Vezes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{color: '#2d75be'}} rowSpan='3'>Ciclo</th>
              <td>Semanal</td>
              <td>1.20% a.s</td>
              <td>1.05% a.s</td>
              <td>0.90% a.s</td>
              <td>Não se aplica</td>
              <td>12x</td>
            </tr>
            <tr>
              <td>Quinzenal</td>
              <td>2.90% a.q</td>
              <td>2.75% a.q</td>
              <td>2.00% a.q</td>
              <td>Não se aplica</td>
              <td>6x</td>
            </tr>
            <tr>
              <td>Mensal</td>
              <td>7.80% a.m</td>
              <td>6.50% a.m</td>
              <td>4.50% a.m</td>
              <td>3.99% a.m</td>
              <td>3x</td>
            </tr>
          </tbody>
        </table>

        <h4>Legendas:</h4>
        <div className='conditions'>
          <h5>a.s: <span style={{fontWeight: 'normal'}}>A semana.</span></h5>
        </div>
        <div className='conditions'>
          <h5>a.q: <span style={{fontWeight: 'normal'}}>A quinzena.</span></h5>
        </div>
        <div className='conditions'>
          <h5>a.m: <span style={{fontWeight: 'normal'}}>Ao mês.</span></h5>
        </div>
        <div className='conditions'>
          <h5>Sem garantia: <span style={{fontWeight: 'normal'}}>Cheque-promissória + Contrato.</span></h5>
        </div>
        <div className='conditions'>
          <h5>Semi-garantia: <span style={{fontWeight: 'normal'}}>Cheque-Promissória, Cheque-calção (Outra titularidade) + contrato.</span></h5>
        </div>
        <div className='conditions'>
          <h5>Garantia parcial: <span style={{fontWeight: 'normal'}}>Cheque-Promissória + Posse de Bens (Joias, Equipamentos, Veículos, imóveis) + Contrato.</span></h5>
        </div>
        <div className='conditions'>          
          <h5>Garantia integral: <span style={{fontWeight: 'normal'}}>Cartão de Crédito ou Cartão CredTech + Contrato.</span></h5>
        </div>
      </section>
      <section style={{paddingLeft: '10%', paddingRight:'10%',boxSizing: 'border-box', marginBottom:'150px'}}>
        <table className='tablecards desk'>
          <thead>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th colSpan="4">Empréstimo com cartão de crédito</th>
            </tr>
            <tr>
              <th style={{color: '#2d75be'}}>Vezes</th>
              <th><img alt='' src={Visa}/></th>
              <th><img alt='' src={Elo}/></th>
              <th><img alt='' src={Hiper}/></th>
            </tr>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th></th>
              <th>Visa - Mastercard</th>
              <th>Elo</th>
              <th>Hipercad - Hiper</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>3.69%</td>
              <td>3.99%</td>
              <td>3.92%</td>
            </tr>
            <tr>
              <td>5</td>
              <td>3.12%</td>
              <td>3.69%</td>
              <td>3.30%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2.74%</td>
              <td>2.93%</td>
              <td>2.89%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>2.55%</td>
              <td>2.66%</td>
              <td>2.70%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>2.33%</td>
              <td>2.47%</td>
              <td>2.43%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>2.17%</td>
              <td>2.28%</td>
              <td>2.25%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>2.03%</td>
              <td>2.14%</td>
              <td>2.11%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>1.92%</td>
              <td>2.02%</td>
              <td>1.99%</td>
            </tr>
            <tr>
              <td>12</td>
              <td>1.83%</td>
              <td>1.92%</td>
              <td>1.89%</td>
            </tr>
          </tbody>
        </table>

        <table className='tablecards mobile'>
          <thead>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th colSpan="2">Empréstimo com cartão de crédito</th>
            </tr>
            <tr>
              <th style={{color: '#2d75be'}}>Vezes</th>
              <th><img alt='' src={Visa}/></th>
            </tr>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th></th>
              <th>Visa - Mastercard</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>3.69%</td>
            </tr>
            <tr>
              <td>5</td>
              <td>3.12%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2.74%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>2.55%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>2.33%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>2.17%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>2.03%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>1.92%</td>
            </tr>
            <tr>
              <td>12</td>
              <td>1.83%</td>
            </tr>
          </tbody>
        </table>
        <table className='tablecards mobile'>
          <thead>
            <tr>
              <th style={{color: '#2d75be'}}>Vezes</th>
              <th><img alt='' src={Elo}/></th>
            </tr>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th></th>
              <th>Elo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>3.99%</td>
            </tr>
            <tr>
              <td>5</td>
              <td>3.36%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2.93%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>2.70%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>2.47%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>2.28%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>2.14%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>2.02%</td>
            </tr>
            <tr>
              <td>12</td>
              <td>1.92%</td>
            </tr>
          </tbody>
        </table>
        <table className='tablecards mobile'>
          <thead>
            <tr>
              <th style={{color: '#2d75be'}}>Vezes</th>
              <th><img alt='' src={Hiper}/></th>
            </tr>
            <tr style={{backgroundColor: '#2d75be'}}>
              <th></th>
              <th>Hipercard - Hiper</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>3.92%</td>
            </tr>
            <tr>
              <td>5</td>
              <td>3.30%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2.89%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>2.66%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>2.43%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>2.25%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>2.11%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>1.99%</td>
            </tr>
            <tr>
              <td>12</td>
              <td>1.89%</td>
            </tr>
          </tbody>
        </table>
        <p style={{marginTop: '10px'}}>Todas as taxas são ao mês, ou seja, em um empréstimo em 4 vezes com Visa a taxa final será 4 x 3.69%</p>
      </section>
      <Footer />
    </div>
  );
}

export default Taxas;