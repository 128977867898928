import styled from 'styled-components';

export const EachData = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #3380ce;
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom:20px;
`

export const Page = styled.div`
    margin: 10%;
    display:flex;
    flex-direction: column;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top:20px;
`

export const DataStr = styled.p`
    margin-bottom:5px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
`

export const Container = styled.div`
    background-color: #3380ce;
    border-radius: ${props=>props.height==='auto' ? '0px' : '10px'};
    display: grid;
    grid-template-columns: 1fr 15fr;
    position: relative;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    margin-bottom: ${props=>props.paddingBottom};
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.4s;

    div>span{
        transition: 0.4s;
        transform: rotate(${props=>props.height==='auto' ? '180deg' : '0deg'})
    }
`

export const ContainerContent = styled.div`
    background-color: rgba(250,250,250);
    position: absolute;
    ${props=>props.height==='auto' ? 'transform: scaleY(1)' : 'transform: scaleY(0)'};
    transform-origin: top;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-sizing: border-box;
    top:100%;
    z-index:0;
    transition: 0.4s;
    color: #3380ce;

    >*{
        transition: 0.4s;
        transition-delay: 2s;
        display: ${props=>props.height==='auto' ? 'flex' : 'transform: scaleY(0)'};
    }
`