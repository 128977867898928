import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import step1 from '../../images/step1.png'
import step2 from '../../images/step2.png'
import step3 from '../../images/step4.png'
import step4 from '../../images/step3.png'
import styled from 'styled-components'

export const CicleContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;

    >img{
        width: auto;
        height: auto;

        max-width: 100%;
    }
`;

export const Grid4Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: 900px) {
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr;
    }
`;

export default function Cicle(){
    return(
        <Grid4Container>
            <ScrollAnimation animateIn="bounceInLeft" duration={1} animateOnce={true}>
                <CicleContainer>
                    <img alt='' src={step1}></img>
                    <h4>Cadastre-se</h4>
                    <p>Faça um cadastro gratuitamente, nos passando apenas algumas informações para podermos avaliar seu pedido e entrar em contato com você rapidamente.</p>
                </CicleContainer>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInLeft" duration={1.5} animateOnce={true}>
                <CicleContainer>
                    <img alt='' src={step2}></img>
                    <h4>Aguarde nossa avaliação</h4>
                    <p>Após enviado os dados, nossa equipe fará uma análise de crédito e entrará em contato com você o mais rápido possível.</p>
                </CicleContainer>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInLeft" duration={2} animateOnce={true}>
                <CicleContainer>
                    <img alt='' src={step3}></img>
                    <h4>Assine o contrato</h4>
                    <p>Feita a análise de crédito, enviaremos um contrato para que você possa assinar e confirmar todos os dados da operação.</p>
                </CicleContainer>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInLeft" duration={2.5} animateOnce={true}>
                <CicleContainer>
                    <img alt='' src={step4}></img>
                    <h4>Receba seu dinheiro!</h4>
                    <p>Depois de confirmarmos todas as informações enviaremos o valor solicitado para sua conta em até 24 horas.</p>
                </CicleContainer>
            </ScrollAnimation>
        </Grid4Container>
    )
}
