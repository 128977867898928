import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  align-items: center;
  margin: 0px auto 10% auto;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  box-sizing: border-box;

  @media (orientation: portrait) {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;

    &>:last-child{
      grid-row: 1/1;
    }
  }

  @media only screen and (max-width: 900px) {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;

    &>:last-child{
      grid-row: 1/1;
    }
  }
`;

export const Form = styled.div`
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-grow: 0.5;
  margin-right: 20px;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  position: relative;

  @media (orientation: portrait) {
    flex-direction: column;
    margin-right: 0px;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-right: 0px;

    h5{
      font-size:14px;
    }
    p{
      font-size:12px;
    }
  }
`;
