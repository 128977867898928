import { Card, CardArea } from "./card-feature.style";

import React from "react";
import ScrollAnimation from 'react-animate-on-scroll'
import cap from "../../images/giro.png";
import cred from "../../images/credito.png";
import emp from "../../images/emprestimo.png";
import plan from "../../images/planos.png";

export default function Features() {
    return (
        <CardArea>
            <Card>
                <ScrollAnimation animateIn="bounceInUp" duration={1} animateOnce={true}>
                    <img alt="" src={cap}></img>
                    <p>
                        Para microempresas, MEI, lojistas, comerciantes, autônomos ou
                        liberais.
                    </p>
                </ScrollAnimation>
            </Card>
            <Card>
                <ScrollAnimation animateIn="bounceInUp" duration={1.5} animateOnce={true}>
                    <img alt="" src={emp}></img>
                    <p>Sem burocracia através de cheque, promissória ou cartão.</p>
                </ScrollAnimation>
            </Card>
            <Card>
                <ScrollAnimation animateIn="bounceInUp" duration={2} animateOnce={true}>
                    <img alt="" src={cred}></img>
                    <p>
                        Com ou sem garantia através de contrato, carnê ou penhora.
                    </p>
                </ScrollAnimation>
            </Card>
            <Card>
                <ScrollAnimation animateIn="bounceInUp" duration={2.5} animateOnce={true}>
                    <img alt="" src={plan}></img>
                    <p>Planos fexíveis: Semanais, quinzenais ou mensais.</p>
                </ScrollAnimation>
            </Card>
        </CardArea>
    );
}
