import './faq.css'

import {Content, Header, Questions} from './faq.styles'
import React,{useEffect, useState} from 'react';

import {BgContainer} from '../../components/header/header.styles'
import FaqElement from '../../components/faqElement/faqElement'
import Footer from '../../components/footer/footer'
import Navbar from '../../components/navbar/navbar'
import { SocialIcon } from "react-social-icons"
import bg from '../../images/bg.png'
import bg2 from '../../images/bg2.png'

export default function FAQ() {
  const [margin,setMargin] = useState(false)

  useEffect(()=> {
    setMargin(document.getElementById('1').clientHeight+"px")
  },[])

  return (
    <div style={{position: 'relative'}}>
      <div className="wppFixed">
        <SocialIcon
          bgColor="#f39d09"
          fgColor="#fff"
          network="whatsapp"
          onClick={() => document.getElementById("wppSend").click()}
        />
        <a
          id="wppSend"
          style={{ display: "none" }}
          href="https://api.whatsapp.com/send?phone=558494418757&text=Gostaria%20de%20saber%20mais%20sobre%20os%20tipos%20de%20empr%C3%A9stimos."
        >{null}</a>
      </div>
      <Header id='1' marginbottom={margin}>
        <div style={{padding: "0 10% 0 10%"}}>
          <Navbar/>
        </div>
        <Content>
          <h2 className='light'>FAQ</h2>
          <h5 className='light' style={{textAlign: 'center'}}>Tire suas dúvidas sobre nosso negócio!</h5>
        </Content>
        <BgContainer img={bg2} speed='13s' height top='50%'/>
        <BgContainer img={bg} speed='10s' height top='50%'/>
      </Header>
      <Questions>
        <FaqElement title='Quem é CredTech?' 
        content='Somos uma Empresa Simples de Crédito - ESC. Incentivamos o 
        fomento comercial e a expansão de pequenos e médios negócios, seja Autônomos, MEI, 
        Microempresa ou Empresa de Pequeno Porte através de operações de Empréstimo, Desconto 
        de Títulos e Financiamentos. '/>
        <FaqElement title='A CredTech é um correspondente bancário, Banco ou Financeira?' 
        content='Não. A CredTech é uma empresa de sociedade limitada totalmente independente da burocracia 
        dos Bancos e atua com recursos próprios para melhor atender nossos clientes. Por esse motivo 
        temos as melhores condições e taxas do mercado.'/>
        <FaqElement title='Como faço para solicitar um Empréstimo?' 
        content='Você pode utilizar nosso Simulador e logo em seguida preencher o formulário de solicitação aqui no site. Em Até 48h úteis você receberá o resultado 
        da sua solicitação ou um dos nossos consultores entrará em contato. Você também pode entrar em contato conosco via whatsapp, telefone ou e-mail.'/>
        <FaqElement title='Quais os requisitos para solicitar um Empréstimo, Desconto de Título ou Financiamento?' 
        content='Para aumentar as chances de seu crédito ser aprovado é desejável (não obrigatório) que você ou sua empresa tenham:
        - Ponto comercial fixo consolidado próprio ou alugado;
        - Comprovante de Renda ou Faturamento;
        - CNPJ/CPF sem restrições;
        - Ter 1 ou mais devedores Solidários;
        - Ter cheque calção do Devedor Solidário;
        - Ter conta corrente com Cheque;'/>
        <FaqElement title='Qual o prazo para liberação do empréstimo?' 
        content='Caso seja aprovado, o dinheiro da operação de crédito estará em sua contra corrente em até 48h após a confirmação e assinatura do contrato.'/>
        <FaqElement title='Qual o prazo de pagamento de um empréstimo, Desconto de Título ou Financiamento?' 
        content='Nossos prazos variam de acordo com os valores solicitados, ciclos de reembolso (Semanal, Quinzenal ou Mensal) e o tipo de garantia. 
        Você pode solicitar operações com prazo mínimo de uma semana e o prazo máximo de três meses. '/>
        <FaqElement title='Qual a região de atuação da CredTech?' 
        content='A credtech está localizada no Rio Grande do Norte. Nossa matriz está localizada na cidade de Natal-RN. Outras cidades de forte 
        atuação são: Parnamirim, Macaiba e extremoz. Em breve estaremos atuando na cidades de Caicó, Santa Cruz e Currais novos.'/>
        <FaqElement title='Quais os valores mínimos e máximos que posso solicitar?' 
        content='Os valores solicitados variam entre R$ 200,00 e R$ 1.000,00. Dependendo do porte da empresa bem como o relacionamento e histórico de operações do cliente, 
        o valor do limite de crédito pode aumentar gradativamente.'/>
      </Questions>
      <Footer />
    </div>
  );
}