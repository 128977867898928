import { Brand, BrandElements, NavbarArea, NavbarItem, NavbarItemBrand, NavbarItensArea, NavbarSelectArea, NavbarToggler } from './navbar.styles'
import React, { memo, useEffect, useState } from 'react';

import { Animated } from "react-animated-css";
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import logo from '../../images/logo.png'

function Navbar() {
  const [navbarState, setNavbarState] = useState('none')
  const [navbarStateHeight, setNavbarStateHeight] = useState('27px')
  const [animateState, setAnimateState] = useState(true)
  const [menuIcon, setMenuIcon] = useState('menu')

  function navbarToggler() {
    if (navbarState === 'none') {
      setNavbarState('block')
      setNavbarStateHeight('155px')
      setAnimateState(true)
      transitionIcon('close')
    }
    else {
      transitionIcon('menu')
      setNavbarState('none')
      setNavbarStateHeight('27px')
      setAnimateState(false)
    }
  }

  function transitionIcon(type){
    let iconNav = document.getElementById('toggler')
    iconNav.animate([
      { opacity: 1 }, 
      { opacity: 0 },
    ], { 
      duration: 300,
      iterations: 2,
      direction: 'alternate'
    })
    iconNav.animate([
      { transform: 'rotate(0deg)' }, 
      { transform: 'rotate(180deg)' },
    ], { 
      duration: 150,
    }).onfinish = () => {
      setMenuIcon(type)
      iconNav.animate([
        { transform: 'rotate(180deg)' }, 
        { transform: 'rotate(360deg)'  },
      ], { 
        duration: 150,
      })
    }
  }

  function deviceDetect() {
    if (window.innerWidth < 900) {
      setAnimateState(false)
    } else {
      setAnimateState(true)
      setNavbarState('block')
    }
  }

  useEffect(()=>{
    window.addEventListener('resize',()=>{
      deviceDetect()
    })
    
    return () => window.removeEventListener('resize',()=>{
      deviceDetect()
    })
  },[])

  return (
    <NavbarArea active={navbarStateHeight} bg={props => props.bg} onLoad={()=>deviceDetect()}>
      <NavbarItensArea>
        <Animated animationIn={"bounceInLeft"} isVisible={true}>
            <BrandElements>
              <Link to='../' style={{ textDecoration: 'none' }}>
                <Brand>
                  <img alt='logo' src={logo} widht='21px' height='21px' />
                  <NavbarItemBrand>CredTech</NavbarItemBrand>
                </Brand>
              </Link>
              <NavbarToggler onClick={() => navbarToggler()}>
                <Icon style={{ color: "#fff" }} id='toggler'>{menuIcon}</Icon>
              </NavbarToggler>
            </BrandElements>
        </Animated>
        <Animated animationIn={"bounceInLeft"} animationOut={'bounceOutRight'} isVisible={animateState} style={{display: animateState===false ? 'none' : 'flex'}}>
          <NavbarSelectArea>
            <div className='linkContainer elementGap'>
              <div>
                <Link to='../taxas' style={{ textDecoration: 'none' }}>
                  <span className='link'>
                    <NavbarItem state={navbarState}>Taxas</NavbarItem>
                  </span>
                  <div className='linkLine' />
                </Link>
              </div>
            </div>
            <div className='linkContainer elementGap'>
              <div>
                <Link to='../solicitaremprestimo' style={{ textDecoration: 'none' }}>
                  <span className='link'>
                    <NavbarItem state={navbarState}>Solicitar empréstimo</NavbarItem>
                  </span>
                  <div className='linkLine' />
                </Link>
              </div>
            </div>
            <div className='linkContainer elementGap'>
              <div>
                <Link to='../descontodetitulos' style={{ textDecoration: 'none' }}>
                  <span className='link'>
                    <NavbarItem state={navbarState}>Trocar cheques</NavbarItem>
                  </span>
                  <div className='linkLine' />
                </Link>
              </div>
            </div>
            <div className='linkContainer elementGap'>
              <div>
                <Link to='../QuemSomos' style={{ textDecoration: 'none' }}>
                  <span className='link'>
                    <NavbarItem state={navbarState}>Quem Somos</NavbarItem>
                  </span>
                  <div className='linkLine' />
                </Link>
              </div>
            </div>
            <div className='linkContainer elementGap'>
              <div>
                <Link to='../faq' style={{ textDecoration: 'none' }}>
                  <span className='link'>
                    <NavbarItem state={navbarState}>FAQ</NavbarItem>
                  </span>
                  <div className='linkLine' />
                </Link>
              </div>
            </div>
            <div className='linkContainer elementGap'>
              <div>
                <Link to='../contato' style={{ textDecoration: 'none' }}>
                  <span className='link'>
                    <NavbarItem state={navbarState}>Contato</NavbarItem>
                  </span>
                  <div className='linkLine' />
                </Link>
              </div>
            </div>
          </NavbarSelectArea>
        </Animated>
      </NavbarItensArea>
    </NavbarArea>
  );
}

export default memo(Navbar)