import "./home.css"

import { AnimateBall, AnimatedDiv } from "../components/animated/animateElement"
import {
  BgContainer,
  BgContainer2,
  Header,
} from "../components/header/header.styles"
import React, { useRef } from "react"

import CardRev from "../components/card-review/cardrev"
import Carousel from "react-elastic-carousel"
import Cicle from "../components/cicle/cicle"
import Features from "../components/card-feature/card-feature"
import Footer from "../components/footer/footer"
import Navbar from "../components/navbar/navbar"
import Parceiros from "../components/parceiros/parceiros"
import ScrollAnimation from "react-animate-on-scroll"
import Simulador2 from "../components/simuladorv2/simulador"
import { SocialIcon } from "react-social-icons"
import bg from "../images/bg.png"
import bg2 from "../images/bg2.png"
import pes1 from "../images/peso1.jpg"
import pes2 from "../images/peso2.jpg"
import pes3 from "../images/peso3.jpg"
import pes4 from "../images/pes4.jpg"
import pes5 from "../images/peso8.jpg"
import section from "../images/section.png"
import sectionCloud from "../images/cloud.png"
import tax from "../images/tax.png"
import teste from "../images/teste2.png"

export default function Home() {
  const carousel = useRef(null)

  return (
    <div className="container">
      <div className="wppFixed">
        <SocialIcon
          bgColor="#f39d09"
          fgColor="#fff"
          network="whatsapp"
          onClick={() => document.getElementById("wppSend").click()}
        />
        <a
          id="wppSend"
          style={{ display: "none" }}
          href="https://api.whatsapp.com/send?phone=558494418757&text=Gostaria%20de%20saber%20mais%20sobre%20os%20tipos%20de%20empr%C3%A9stimos."
        >{null}</a>
      </div>
      <Header>
        <Navbar />
        <div className="subHeader">
          <div>
            <div className="animationsOver">
              <AnimateBall
                time="3s"
                ymax="30px"
                ymin="-10px"
                light
                size="70px"
                zIndex="0"
                top="20%"
              />
              <AnimateBall
                time="3s"
                ymax="30px"
                ymin="10px"
                light
                size="40px"
                zIndex="0"
                top="24%"
                left="12%"
              />
              <AnimateBall
                time="3.5s"
                ymax="10px"
                ymin="-10px"
                light
                size="40px"
                zIndex="0"
                left="90%"
                top="10%"
              />
              <img alt="" src={teste} className="headerImg" style={{zIndex: '1',position: 'relative'}}/>
              <p style={{ fontSize: "50%" }}>Designed by Freepik</p>
            </div>
            <h2 className="marginClear" style={{ fontWeight: "bold" }}>
              CONHEÇA A <span className="spotlight">CREDTECH</span>
            </h2>
            <p>
              Nós <span className="spotlight">fomentamos</span> e apoiamos
              empresas através de soluções de crédito com a{" "}
              <span className="spotlight">transparência</span> e a{" "}
              <span className="spotlight">credibilidade</span> que seu negócio
              precisa. Vamos<span className="spotlight"> crescer </span>juntos?
            </p>
          </div>
          <div className="simulador">
            <Simulador2 />
          </div>
        </div>
        <BgContainer img={bg2} speed="15s"/>
        <BgContainer img={bg} speed="10s"/>
      </Header>
      <section
        style={{
          padding: "100px 10% 100px 10%",
          boxSizing: "border-box",
        }}
      >
        <h3 style={{ marginBottom: "50px", textAlign: "center" }}>
          Como funciona?
        </h3>
        <Cicle />
      </section>
      <section
        style={{
          padding: "10% 10% 10% 10%",
          boxSizing: "border-box",
          backgroundColor: "rgb(250,250,250)",
        }}
      >
        <Features />
      </section>
      <section className="vw100 call" style={{ position: "relative" }}>
        <div className="subHeader" style={{ justifyContent: "center" }}>
          <ScrollAnimation
            animateOnce={true}
            animateIn="bounceInLeft"
            style={{ justifyContent: "center" }}
          >
            <div style={{ justifyContent: "center" }}>
              <h3 className="light">
                Buscamos oferecer{" "}
                <span className="spotlight">soluções de crédito</span> e
                serviços financeiros que{" "}
                <span className="spotlight">superem as expectativas</span>,
                tragam inovações, diferenciais e principalmente{" "}
                <span className="spotlight">vantagens competitivas</span> para
                nossos clientes.
              </h3>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInRight"
            animateOnce={true}
            style={{ justifyContent: "center" }}
          >
            <div style={{ justifyContent: "center", position: "relative" }}>
              <AnimatedDiv time="2s" ymax="25px" ymin="-25px" zIndex="1">
                <img
                  alt="impulsionamento"
                  src={section}
                  className="headerImg"
                />
              </AnimatedDiv>
              <AnimatedDiv time="4s" ymax="15px" ymin="-15px" zIndex="0">
                <img
                  alt="impulsionamentoCloud"
                  src={sectionCloud}
                  className="headerImg"
                />
              </AnimatedDiv>

              <img
                alt="clear"
                src={sectionCloud}
                className="headerImg"
                style={{ opacity: "0%" }}
              />
              <AnimateBall
                time="3s"
                ymax="30px"
                ymin="-10px"
                light
                size="40px"
                zIndex="2"
                top="80%"
              />
              <AnimateBall
                time="3.5s"
                ymax="10px"
                ymin="-10px"
                light
                size="40px"
                zIndex="2"
                left="80%"
                top="30%"
              />
            </div>
          </ScrollAnimation>
        </div>
        <BgContainer2 img={bg2} speed="15s" pos="center" />
        <BgContainer2 img={bg} speed="10s" pos="center" />
        <div
          style={{
            backgroundColor: "rgb(250,250,250)",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "-2",
          }}
        />
      </section>
      <section className="taxasSection">
        <div style={{ padding: "10%" }}>
          <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
            <img alt="clear" src={tax} className="headerImg graph" id="graph" />
          </ScrollAnimation>
        </div>
        <div>
          <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
            <h3>Porque nossas taxas são tão baixas?</h3>
            <p style={{ textAlign: "justify" }}>
              A CredTech é uma empresa de sociedade limitada totalmente
              independente da burocracia dos Bancos e atua com recursos próprios
              para melhor atender nossos clientes. Por esse motivo temos as
              melhores condições e taxas do mercado.
            </p>
          </ScrollAnimation>
        </div>
      </section>
      <section
        style={{ padding: "100px 10% 100px 10%", boxSizing: "border-box" }}
      >
        <h3 style={{ marginBottom: "50px", textAlign: "center" }}>
          Conheça alguns de nossos parceiros
        </h3>
        <Parceiros />
      </section>
      <section className="reviews">
        <h3 style={{ textAlign: "center", marginBottom: "50px" }}>
          Pessoas que confiam na nossa empresa
        </h3>
        <Carousel
          showArrows={false}
          enableAutoPlay={true}
          autoPlaySpeed={15000}
          ref={carousel}
          className="carrouselTheme"
          onNextEnd={currentItem => {
            if (carousel.current !== null){
              if (window.innerWidth/window.innerHeight > 1 && currentItem.index === 2) {
                setTimeout(() => {
                  if (carousel.current !== null && currentItem.index === 2) {
                    carousel.current.goTo(0)
                  }
                }, 5000)
              }
              if (window.innerWidth/window.innerHeight <= 1 && currentItem.index === 4) {
                setTimeout(() => {
                  if (carousel.current !== null && currentItem.index === 4) {
                    carousel.current.goTo(0)
                  }
                }, 15000)
              }
            }
          }}
          itemsToScroll={1}
          breakPoints={[
            { width: 0, itemsToShow: 1 },
            { width: 1080, itemsToShow: 3 },
          ]}
        >
          <ScrollAnimation
            animateIn="bounceInUp"
            duration={1}
            animateOnce={true}
          >
            <CardRev
              img={pes1}
              name="Alexandre Pinto"
              func="CEO Colégio Ciências Aplicadas - Natal - RN"
              content='"Trabalhamos juntos desde 2018 e até então vem sendo consolidada uma parceria fantástica. Sinceramente, o Isaac Danilo é um cara idôneo, preparado, inteligente, disponível e muito compreensível. A sua empresa é séria, confiável e que vale a pela fechar negócio. Recomendo a todos."'
            />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInUp"
            duration={1.5}
            animateOnce={true}
          >
            <CardRev
              img={pes2}
              name="Marcos Oliveira"
              func="CEO NatalMakers - Natal - RN"
              content='"O que procuramos em um parceiro de negocios encontramos na CredTech: transparência no processo, agilidade no atendimento, disponibilidade no atendimento e  principalmente a confiança, elemento essencial para o fechamento de um bom negócio. Altamente recomendada!"'
            />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInUp"
            duration={2}
            animateOnce={true}
          >
            <CardRev
              img={pes3}
              name="Bráulio Castillo"
              func="CEO Mulambo Novo - Natal - RN"
              content='"Conheço Isaac há 25 anos. São mais de duas décadas de parceria. Quanto mais o tempo passa, mais confio nele. Um homem justo, proativo e solidário. Quando vou escolher parceiros para expandir meu negócio, escolho sua empresa sem hesitar. É a melhor taxa com o melhor atendimento.”'
            />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInUp"
            duration={2}
            animateOnce={true}
          >
            <CardRev
              img={pes4}
              name="Manassés Máximo"
              func="CEO Pastelouco - Parnamirim - RN"
              content='"A CredTech é uma grande parceira sempre que precisamos fazer expansão ou investir no nosso negócio. É uma empresa coerente e a indico porque tem operações rápidas, taxa justa, bom atendimento e flexibilidade para o empresário."'
            />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInUp"
            duration={2}
            animateOnce={true}
          >
            <CardRev
              img={pes5}
              name="Luciano Nascimento e Dinah Ciriaco"
              func="CEOs Presentei - Natal-RN"
              content='"Indicamos a CredTech porque consideramos uma Fintech sólida que agiliza a liquidez da nossa empresa de forma rápida e sem a burocracia dos bancos. Sem falar que podemos antecipar cheques online de forma segura com a credibilidade e confiança de profissionais fantásticos. Parceiro excelente!”'
            />
          </ScrollAnimation>
        </Carousel>
      </section>
      <Footer />
    </div>
  )
}
