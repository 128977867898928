import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";

import Admin from './pages/admin/admin'
import Contato from './pages/contato/contato'
import DesTit from './pages/des_tit/des_tit'
import FAQ from './pages/faq/faq'
import Home from './pages/home'
import QuemSomos from './pages/quem_somos/quem_somos'
import React from "react";
import SolImp from './pages/soli_emp/soli_emp'
import Taxas from './pages/taxas/taxas'

export default function Routes() {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/taxas">
            <Taxas />
          </Route>
          <Route path="/solicitaremprestimo">
            <SolImp />
          </Route>
          <Route path="/solicitaremprestimo/:querry">
            <SolImp />
          </Route>
          <Route path="/descontodetitulos">
            <DesTit />
          </Route>
          <Route path="/quemsomos">
            <QuemSomos />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/contato">
            <Contato />
          </Route>
          <Route path="/adminHome">
            <Admin />
          </Route>
        </Switch>
    </BrowserRouter>
  );
}