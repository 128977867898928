import "./quem_somos.css"

import { Content, Header } from "../faq/faq.styles"
import React, { useEffect, useState } from "react"

import { Animated } from "react-animated-css"
import { BgContainer } from "../../components/header/header.styles"
import Credbility from "../../images/credbility.png"
import Equipe from "../../images/equipe.png"
import Eye from "../../images/eye.png"
import Footer from "../../components/footer/footer"
import LogoAzul from "../../images/logo_blue.png"
import Navbar from "../../components/navbar/navbar"
import ScrollAnimation from "react-animate-on-scroll"
import { SocialIcon } from "react-social-icons"
import Target from "../../images/target.png"
import Taxas from "../../images/taxas.png"
import Tranparencia from "../../images/transparencia.png"
import Values from "../../images/values.png"
import Velocidade from "../../images/velocidade.png"
import bg from "../../images/bg.png"
import bg2 from "../../images/bg2.png"
import manager from "../../images/ManagerImg.jpeg"

// imagens

function QuemSomos() {
  const [margin, setMargin] = useState(false)

  useEffect(()=> {
    setMargin(document.getElementById('1').clientHeight+"px")
  },[])

  return (
    <div id="container" style={{overflowX: 'hidden'}}>
      <div className="wppFixed">
        <SocialIcon
          bgColor="#f39d09"
          fgColor="#fff"
          network="whatsapp"
          onClick={() => document.getElementById("wppSend").click()}
        />
        <a
          id="wppSend"
          style={{ display: "none" }}
          href="https://api.whatsapp.com/send?phone=558494418757&text=Gostaria%20de%20saber%20mais%20sobre%20os%20tipos%20de%20empr%C3%A9stimos."
        >{null}</a>
      </div>
      <Header id="1" marginbottom={margin}>
        <div style={{ padding: "0 10% 0 10%" }}>
          <Navbar />
        </div>
        <Content>
          <h3
            className="light"
          >
            Quem somos
          </h3>
          <h5 className="light" style={{textAlign: 'center'}}>Saiba mais sobre nossa empresa!</h5>
        </Content>
        <BgContainer img={bg2} speed="13s" height top="50%" />
        <BgContainer img={bg} speed="10s" height top="50%" />
      </Header>

      <div id="cont">
        <Animated animationIn={"bounceInRight"}>
          <div id="about">
            <img alt="" src={LogoAzul}/>
            <div id="desc">
              <h3 className="spotlight">A CredTech</h3>
              <p>
                A CREDTECH é uma Empresa Simples de Crédito (ESC) que está
                voltada à realização de operações de empréstimo, de
                financiamento e de desconto de títulos de crédito,
                exclusivamente com recursos próprios, tendo como beneficiados
                microempreendedores individuais, microempresas e empresas de
                pequeno porte, nos termos da Lei Complementar (LC) 167/2019.
              </p>
              <p>
                A empresa visa inserir dinheiro na economia por meio de fomento
                comercial através de microcrédito, uma forma distinta da
                intermediação bancária e do contrato de factoring.
              </p>
            </div>
          </div>
        </Animated>

        <Animated animationIn={"bounceInLeft"}>
          <div id="apresentation">
            <div id="person">
              <img alt="" src={manager} className="imgManager"></img>
            </div>
            <div id="personDesc">
              <h3 id="name">Isaac Danilo</h3>
              <h4 className="spotlight">Diretor de Crédito</h4>
              <div id="bio">
                <p>
              •	Mestrado em Engenharia de Software pela UFRN; 
              Especialista em Computação Forense pela UnP e Graduado em Sistemas 
              de Informação pela UFRN.
                </p>
                <p>
              •	Sócio da CREDTECH Empresa Simples de Credito e da F5 Tecnologia 
              e Consultoria LTDA.
              </p>
                <p>
              •	Atualmente está Professor do Instituto Federal do
               Rio Grande do Norte (IFRN) desde 2016; Professor de Tecnologia, 
               Empreendedorismo e Educação Financeira no Colégio Ciências Aplicadas desde 2019; Professor intermitente do SENAI desde 2017 e Professor Universitário desde 2013.
               </p>
              <p>
              •	Possui certificação Microsoft e diversos cursos na área de TI,
               Finanças e educação financeira.
               </p>
               <p>

              •	Atua como Perito Forense Computacional particular ou 
              assistente técnico judicial nas esferas cível, criminal e 
              trabalhistas desde 2017.
              </p>
              <p>
              •	Atuou por 5 anos como Professor Universitário com ênfase 
              na área de Engenharias e TI nos cursos de Gestão da TI, 
              Sistemas de Informação, Engenharia da Computação, Administração, 
              Ciência da Computação, Redes de Computadores e Análise e Desenvolvimento
               de Sistemas, além de ter atuado nos cursos Técnicos e Pós-Graduação da
                Universidade Potiguar (UnP).
                </p>

              <p>
              •	Foi Professor executivo convidado da Fundação Getúlio Vargas (FGV) em 2017.
              </p>

              <p>
              •	Atuou como docente e tutor do Instituto Metrópole 
              Digital (UFRN) e da Escola Agrícola de Jundiaí (UFRN) nos
              cursos relacionados a Computação e afins de 2014 a 2017.
              </p>
              <p>
              •	Foi Instrutor na Microlins Formação profissional de 2013 a 
              2016, com experiências em educação profissionalizante e à distância.
              </p>
              </div>
            </div>
          </div>
        </Animated>

        <div id="cards">
          <div id="card">
            <ScrollAnimation animateIn={"bounceInUp"} animateOnce={true}>
              <img alt="" src={Target}></img>
              <h3 className="spotlight">Nossa missão</h3>
              <p align="justify">
                Oferecer soluções de crédito e serviços financeiros que superem
                as expectativas de nossos clientes; construir um legado com uma
                marca forte, trazendo sempre inovações e diferenciais,
                proporcionando vantagem competitiva para nossos clientes.
              </p>
            </ScrollAnimation>
          </div>

          <div id="card">
            <ScrollAnimation animateIn={"bounceInUp"} animateOnce={true}>
              <img alt="" src={Eye}></img>
              <h3 className="spotlight">Nossa visão</h3>
              <p align="justify">
                Ser reconhecida como a maior empresa em soluções de credito e na
                prestação de serviços financeiros, com um portfólio
                diversificado e gerar impacto social, atendendo da melhor forma
                as necessidades dos empreendedores. Atuar na prestação de
                serviços e soluções atingindo a cidade de Natal-RN e os
                municípios limítrofes e, em breve, uma ampla rede de fomento
                comercial.
              </p>
            </ScrollAnimation>
          </div>

          <div id="card">
            <ScrollAnimation animateIn={"bounceInUp"} animateOnce={true}>
              <img alt="" src={Values}></img>
              <div>
                <h3 className="spotlight">Nossos valores</h3>
                <div align="center">
                  <p>Solidez financeira.</p>
                  <p>Velocidade e agilidade.</p>
                  <p>Credibilidade, Confiança e Ética.</p>
                  <p>Taxas acessíveis.</p>
                  <p>Equipe de alto nível.</p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <div id="achivs">
        <ScrollAnimation
          animateIn={"slideInLeft"}
          animateOnce={true}
          delay={300}
        >
          <div className="achiv">
            <img alt="" src={Tranparencia}></img>
            <h3>Transparência nas negociações</h3>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn={"slideInLeft"}
          animateOnce={true}
          delay={600}
        >
          <div className="achiv">
            <img alt="" src={Equipe}></img>
            <h3>Equipe treinada</h3>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn={"slideInLeft"}
          animateOnce={true}
          delay={900}
        >
          <div className="achiv">
            <img alt="" src={Taxas}></img>
            <h3>Melhores taxas</h3>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn={"slideInLeft"}
          animateOnce={true}
          delay={1200}
        >
          <div className="achiv">
            <img alt="" src={Velocidade}></img>
            <h3>Análise ágil, rápida e eficaz</h3>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn={"slideInLeft"} animateOnce={true}>
          <div className="achiv">
            <img alt="" src={Credbility}></img>
            <h3>Grupo de confiança, ética e credibilidade</h3>
          </div>
        </ScrollAnimation>
      </div>

      <Footer/>
    </div>
  )
}

export default QuemSomos
