import styled from "styled-components";

export const CardArea = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    transition: 0.5s;

    @media only screen and (max-width: 1250px) {
        flex-direction: column;
    }
`;
export const Card = styled.div`
    height: fit-content;
    width: 16%;
    transition: 0.4s;

    img {
        width: 100%;
        transition: 0.4s;
    }

    &:hover {
        transform: scale(1.1);
        transition: 0.4s;
    }
    &:hover > div > img {
        transform: rotate(25deg);
    }

    @media only screen and (max-width: 1250px) {
        width: 80%;
        height: 30%;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        img {
            width: 30%;
        }
    }
`;
