import styled from 'styled-components';

export const Container = styled.div`
    width: 400px;
    height: 50%;
    display: grid;
    grid-template-rows: 1fr 4fr;
    grid-template-columns: 1fr 4fr;
    padding:10px;
    box-sizing:border-box;
    grid-row-gap:10px;
    grid-column-gap:10px;

    @media only screen and (max-width: 900px){
        width: 100%;
    }
`;

export const Text = styled.div`
    border-top: 1px solid rgb(230,230,230);
    padding-top:10px;
    grid-row: 2/2;
    grid-column: 1/3;
    text-align: justify;
`

export const NameContainer = styled.div`
    grid-row: 1/1;
    grid-column: 2/3;
    justify-self:center;
    align-self:center;
`

export const Img = styled.img`
    max-width:100%;
    width: auto;
    height: auto;
    object-fit: cover;
    object-position: center;
    justify-self:center;
    align-self:center;
    border-radius: 50%;
`
