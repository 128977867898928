import styled from 'styled-components';

export const Header = styled.div`
    position: relative;
    margin-bottom: ${props=>props.marginbottom === '122px' ? '100px' : props.marginbottom};
    background-color: #3380ce;

    > div > h5{
        margin: 0 10% 0 10%;
    }
`;

export const Content = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`

export const Questions = styled.div`
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
`
