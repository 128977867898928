import React,{useState} from 'react'

import Icon from '@material-ui/core/Icon';
import {Container, Content } from './faqElement.styles'

export default function FaqElement(props){
    const [open, setOpen] = useState(false)
    return(
        <Container height={open ? 'auto' : '0px'} paddingBottom={open ? (document.getElementById('content').clientHeight+10)+"px" : '10px'}>
            <div style={{cursor:'pointer'}} onClick={()=>setOpen(!open)}>
                <Icon>{'expand_more'}</Icon>
            </div>
            <p style={{cursor:'pointer'}} onClick={()=>setOpen(!open)}>{props.title}</p>
            <Content height={open ? 'auto' : '0px'} id='content'>
                <p>{props.content}</p>
            </Content>
        </Container>
    )
}