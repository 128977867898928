import {
  BgContainer,
  BottomOver,
  MainContainer,
  Social,
  SubContainer,
} from "./footer.styles"
import React,{memo} from "react"

import { SocialMediaIconsReact } from "social-media-icons-react"
import bg from "../../images/bg.png"
import bg2 from "../../images/bg2.png"

function Footer() {
  return (
    <div style={{ position: "relative" }}>
      <BgContainer img={bg2} speed="15s" />
      <BottomOver />
      <BgContainer img={bg} speed="10s" />
      <MainContainer>
        <SubContainer>
          <div className="linkContainer horGap">
            <div>
              <p className="marginClear link" onClick={()=> {
                navigator.clipboard.writeText("Credtechnatal@gmail.com");
                document.execCommand('copy')
                alert('Copiado para área de transferência')
              }}>
                <strong>Email:</strong> Credtechnatal@gmail.com{" "}
              </p>
              <div className="linkLine" />
            </div>
          </div>
          <div className="linkContainer horGap">
            <div>
              <p className="marginClear link" onClick={()=> {
                navigator.clipboard.writeText("(84) 99408-4212");
                document.execCommand('copy')
                alert('Copiado para área de transferência')
              }}>
                <strong>Telefone(1):</strong> (84) 99408-4212{" "}
              </p>
              <div className="linkLine" />
            </div>
          </div>
          <div className="linkContainer horGap">
            <div>
              <p className="marginClear link" onClick={()=> {
                navigator.clipboard.writeText("(84) 99970-2626");
                document.execCommand('copy')
                alert('Copiado para área de transferência')
              }}>
                <strong>Telefone(2):</strong> (84) 99970-2626
              </p>
              <div className="linkLine" />
            </div>
          </div>
        </SubContainer>
        <SubContainer>
          <Social style={{height: '100%'}}>
            <SocialMediaIconsReact
              backgroundColor="#fff"
              icon="instagram"
              iconColor="#3380ce"
              size={"48px"}
              roundness="100%"
              iconSize={15}
              url='https://www.instagram.com/credtech.me/'
            />
            <SocialMediaIconsReact
              backgroundColor="#fff"
              icon="facebook"
              iconColor="#3380ce"
              size={"48px"}
              roundness="100%"
              iconSize={15}
              url='https://www.facebook.com/credtech/'
            />
            <SocialMediaIconsReact
              backgroundColor="#fff"
              icon="youtube"
              iconColor="#3380ce"
              size={"48px"}
              roundness="100%"
              iconSize={15}
              url='https://www.youtube.com/channel/UClQRIO3lIN9NpFSbj0q60iA'
            />
          </Social>
        </SubContainer>
        <SubContainer>
          <div className="linkContainer horGap">
            <div>
              <p className="marginClear" style={{marginBottom: '20px', fontSize: '80%'}}>
                <br/> A CredTech é uma Empresa Simples
                de Crédito pertencente ao Grupo ID soluções de crédito e T.I.
                Somos uma empresa de Capital próprio e independente de qualquer
                burocracia dos grandes Bancos, instituições financeiras e seus
                aglomerados. Estamos situados na Rua Orlando De Lima, 465, Bloco D
                – Ponta Negra, Natal – RN, CEP 59091-020.<br/><br/> A CredTech não cobra
                nenhum valor antecipado para aprovar ou depositar seu
                empréstimo. Caso alguém, se passando pela nossa empresa,
                solicite qualquer depósito antecipado, encerre o contato e fale
                conosco. Você pode estar sendo vítima de uma tentativa de
                fraude. <br/><br/> Nossa análise de crédito é personalizada de acordo com o
                seu perfil e o produto escolhido. Para empréstimo com ou
                sem garantia, a CET varia de 3,99% ao mês (47,88% ao ano) a
                12,4% ao mês (306,63% ao ano). O período de quitação é de 1 (uma
                semana) a 3 meses, podendo ser antecipado a qualquer momento.
                <br/><br/> Exemplo de empréstimo sem garantia com taxa de 3,99% ao mês
                (47,88% ao ano): Valor emprestado: R$ 5.000,00 para pagar em 3
                meses. Total de 3 parcelas de R$1.817,57. Imposto sobre
                operações financeiras (IOF) de R$44,87 e Custo efetivo total
                (CET) da operação de 4,45% ao mês (68,77% ao ano).
              </p>
              <div className="linkLine" />
            </div>
          </div>
        </SubContainer>
      </MainContainer>
    </div>
  )
}

export default memo(Footer)
