import styled from "styled-components";

export const Cont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;; 
    text-align:center;
    margin: 50px 0 150px 0;

    @media (max-width: 950px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const Info = styled.div`
    display: grid;
    grid-template-columns: 25% 75%;   
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    color: #f39d09;
    margin-bottom:20px;

    img{
        width: 80%;
        grid-column: 1;
        grid-row: 1;
        justify-self: center;
        align-self: center;
    }

    div{
        grid-column: 2;
        grid-row: 1;
    }
    @media (max-width: 950px){
        width: 100%;
    }
`;

export const ElementsGrid = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    padding: 0 10% 0 10%;
    grid-column-gap: 20px;

    & > div:last-child{
        grid-column: 1/3;
    }

    @media (max-width: 950px){
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr;

        & > div:nth-child(2){
            grid-row: 3;
        }

        & > div:last-child{
            grid-row: 2;
        }
    }
`