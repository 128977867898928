import styled from "styled-components";

export const ContainerMain = styled.div`
  height: fit-content;
  text-align: center;
  margin-bottom: 50px;
  padding: 0 10% 0 10%;
`;
export const Container = styled.div`
  display: ${(props) => props.display};
  margin: 20px auto;
  flex-direction: column;

  h1 {
    color: #3380ce;
  }
`;

export const Card = styled.div``;

export const CardButton = styled.button`
  margin: 5px;

  border-radius: 4px;
  background-color: white;
  box-shadow: none;
  text-decoration: none;
`;
export const ContainerFirst = styled.div`
  display: ${(props) => props.display};
  width: fit-content;
  height: 400px;
  margin: 20px auto;
  flex-direction: column;
  text-align: center;

  h1 {
    color: #3380ce;
  }
`;

export const SideToSide = styled.div`
  text-align: center;
  box-sizing: border-box;
  padding: 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: rgba(250, 250, 250);
  width: 100%;

  p {
    font-size: 30px;
    margin-bottom: 20px;
  }
`;

export const ButtonContainerSoli = styled.div`
  margin-bottom: 20px;
  display: ${(props) => props.display};

  grid-template-columns: 1fr 3fr 3fr;

  > p {
    border-top: 1px solid #eee;
  }
`;

export const Classic = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;

    h6{
      font-size:12px;
    }
  }
`;

export const SubContainerToDes = styled.div`
    display: flex;
    width: 100%;
`;