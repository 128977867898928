import styled from 'styled-components';

export const NavbarArea = styled.div`
    display: flex;
    padding: 20px 0px 20px 0px;
    height: 21px;
    transition: 0.2s;
    width: 100%;
    background-color: ${props=>props.bg ? '#3380ce' : 'rgba(0,0,0,0)'};

    @media only screen and (max-width: 900px){
        height: ${props=>props.active};
    }
`;

export const Brand = styled.div`
    display: flex;
    align-items: center;
`

export const NavbarItensArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const NavbarSelectArea = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const BrandElements = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const NavbarItem = styled.p`
    font-weight: 200;
    margin: 0 0 0 0;
    transition: 0.2s;
    display: inline-block;
    user-select: none;
    color: white;

    @media only screen and (max-width: 900px) {
        margin-left: 0px;
        display: ${props=>props.state};
    }
`;

export const NavbarItemBrand = styled.p`
    display: inline-block;
    font-weight: bold;
    margin: 0 0 0 0;
    margin-left: 5px;
    color: white;
`;

export const NavbarToggler = styled.div`
    margin: 0 0 0 0;
    cursor: pointer;
    display: none;

    @media only screen and (max-width: 900px) {
        display: flex;
    }
`;