import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10% 10% 10% 10%;
    background-color: rgba(250,250,250);
    width:80%;
    justify-self: center;
    align-self: center;
    position: relative;
    border-radius:10px;
`;

export const NoOverlay = styled.div`
    display: ${props=>props.display};

    >div{
        margin-bottom:25px;
    }
    div:last-child, div:first-child{
        margin-bottom:0px;
    }

    div:last-child>div{
        margin: 0px 0px 0px 0px;
    }
`

export const SubContainer = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    width: 100%;

    >div{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const ButtonContainer = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;

    >div{
        margin:0 0 0 0;
        display: flex;
        justify-content: center;
        align-items:center;
    }
`

export const Overlay = styled.div`
    position: ${props=> props.width==='100%' ? 'flex' : 'absolute'};
    height:100%;
    box-sizing: border-box;
    z-index:2;
    background-color: red;
    top:0;
    left:0;
    transition:0.2s;
    background-color: rgba(250,250,250);
    display: flex;
    flex-direction:column;

    >h5,h4,h3,.MuiFormControl-root{
        display: ${props=>props.width==='100%' ? 'flex' : 'none'}
    }

    & > h5:first-child{
        justify-self: flex-end;
        align-self: flex-end;
        cursor: pointer;
        display: ${props=>props.width==='100%' ? 'block' : 'none'}
    }
    a:nth-last-child(2){
        flex-grow:1;
        display: ${props=>props.width==='100%' ? 'flex' : 'none'};
    }
    a:nth-last-child(2)>div{
        margin: 0px 0px 0px 0px;
        align-self: flex-end;        
        width:100%;
    }
`

export const AlertDiv = styled.div`
    display:${props=>props.display};
    padding:5px;
`

export const ResultGrid = styled.div`
    margin-top:20px;
    display:${props=>props.display};
    
    grid-template-columns: 1fr 3fr 3fr;

    > p{
        border-top: 1px solid #eee;
    }
`

export const AnimationOver = styled.div`
    position: absolute;
    height:100%;
    width:${props=>props.active ? '100%' : '0%'};
    transition: 0.2s;
    z-index:3;
    background-color: #f39d09;
    top: 0;
    left: 0;
    border-radius:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
`

export const AnimationLoading = styled.img`
    animation: loading 2s linear infinite;
    transition: 0.2s;
    display: ${props=>props.isShow ? 'flex' : 'none'};

    @keyframes loading{
        from{
            transform: rotate(0deg)
        }
        to{
            transform: rotate(360deg)
        }
    }
`

export const AnimationMsg = styled.h4`
    color: white;
    transition: 0.2s;
    transform: scaleX(${props=>props.isShow ? '1' : '0'});
    margin: 10px;
`