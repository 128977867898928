import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  display: flex;
  max-width:100%;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
`;

export const BgContainer = styled.div`
  position: absolute;
  width: 110%;
  height: ${props=> props.height ? "100%" : "42vh"};
  z-index: -1;
  overflow: hidden;
  left: -10%;
  background-image: url(${props=>props.img});
  background-position: 0;
  user-select:none;
  animation: animate ${props=>props.speed} linear infinite alternate;
  background-size: cover;
  background-position-y: bottom;
  top: ${props=>props.top};
  

  @media only screen and (max-width: 900px) {
    transform: translateY(-25px);
  };

  @media only screen and (max-height: 680px) and (orientation: landscape){
    height: ${props=> props.height ? "100%" : "82vh"};
  };

  @media only screen and (max-width: 380px){
    height: ${props=> props.height ? "100%" : "35vh"};
  };

  @keyframes animate{
    0%{
      background-position-x: 0px;
    }
    100%{
      background-position-x: 1920px;
    }
  }  
`

export const BgContainer2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background-image: url(${props=>props.img});
  background-position: 0;
  user-select:none;
  animation: animate ${props=>props.speed} linear infinite alternate;
  background-position-y: ${props=>props.pos};
  background-size:cover;


  @media only screen and (max-width: 900px) {
    transform: translateY(-25px);
  };

  @keyframes animate{
    0%{
      background-position-x: 0px;
    }
    100%{
      background-position-x: 1920px;
    }
  }  
`
